import AuthStore from "@/store/modules/AuthStore";
import { Route } from "vue-router";
import { getModule } from "vuex-module-decorators";
const authStore = getModule(AuthStore);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (to: Route, from: Route, next: any) => {
    const checkLogin = authStore.checkLogin() as Promise<boolean>;
    checkLogin.then((result) => {
        if (result) {
            next();
        } else {
            authStore.setPreviousRoute(to);
            next({ name: "login" });
        }
    });
};
