import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import authGuard from "./guard/auth";
import { paginatedPageQueryParams } from "sx-shared-core";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        name: "home",
        path: "/",
        component: () => import("@/views/app/auth/IndexView.vue"),
        redirect: "/training-class",
        children: [
            {
                name: "login",
                path: "login",
                component: () => import("@/views/app/auth/LoginView.vue"),
            },
            {
                name: "post-course-feedback.show",
                path: "/post-course-feedback/:hash",
                component: () =>
                    import(
                        "@/views/app/post-course-feedback/PostCourseFeedbackShowView.vue"
                    ),
            },
        ],
    },
    {
        path: "/app",
        component: () => import("@/views/app/Index.vue"),
        beforeEnter: authGuard,
        redirect: "/app/training-class",
        children: [
            {
                path: "/purchase-order",
                component: () =>
                    import(
                        "@/views/app/purchase-order/PurchaseOrderParentView.vue"
                    ),
                children: [
                    {
                        name: "purchase-order.index",
                        path: "",
                        props: paginatedPageQueryParams({
                            sort: "raisedAt:desc",
                        }),
                        component: () =>
                            import(
                                "@/views/app/purchase-order/PurchaseOrderIndexView.vue"
                            ),
                    },
                    {
                        name: "purchase-order.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/purchase-order/PurchaseOrderShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/training-class",
                component: () =>
                    import(
                        "@/views/app/training-class/TrainingClassParentView.vue"
                    ),
                children: [
                    {
                        name: "training-class.index",
                        path: "",
                        component: () =>
                            import(
                                "@/views/app/training-class/TrainingClassIndexView.vue"
                            ),
                    },
                    {
                        name: "training-class.show",
                        path: ":id",
                        component: () =>
                            import(
                                "@/views/app/training-class/TrainingClassShowView.vue"
                            ),
                    },
                ],
            },
            {
                path: "/calendar",
                name: "calendar.index",
                component: () =>
                    import("@/views/app/calendar/CalendarExportView.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes,
});

export default router;
