export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Iterable` scalar type represents an array or a Traversable with any kind of data. */
  Iterable: any;
  /** The `Upload` type represents a file to be uploaded in the same HTTP request as */
  Upload: any;
};

export type Account = Node & {
  __typename?: 'Account';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal: Scalars['Boolean'];
  name: Scalars['String'];
  organisation?: Maybe<Organisation>;
  type?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};

/** Cursor connection for Account. */
export type AccountCursorConnection = {
  __typename?: 'AccountCursorConnection';
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  pageInfo: AccountPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Account. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String'];
  node?: Maybe<Account>;
};

export type AccountManager = Node & {
  __typename?: 'AccountManager';
  fullName: Scalars['String'];
  id: Scalars['ID'];
  user: User;
};

/** Information about the current page. */
export type AccountPageInfo = {
  __typename?: 'AccountPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** The `EmbeddedObject` scalar type represents file metadata data. */
export type Address = {
  __typename?: 'Address';
  /** Address Line 1 */
  address1?: Maybe<Scalars['String']>;
  /** Address Line 2 */
  address2?: Maybe<Scalars['String']>;
  /** Address Line 3 */
  address3?: Maybe<Scalars['String']>;
  /** Address Line 4 */
  address4?: Maybe<Scalars['String']>;
  /** City/Town */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Postal Code/ZIP */
  postcode?: Maybe<Scalars['String']>;
  /** Region/County */
  region?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  /** Address Line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address Line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** Address Line 3 */
  address3?: InputMaybe<Scalars['String']>;
  /** Address Line 4 */
  address4?: InputMaybe<Scalars['String']>;
  /** City/Town */
  city?: InputMaybe<Scalars['String']>;
  /** Country */
  country?: InputMaybe<Scalars['String']>;
  /** Postal Code/ZIP */
  postcode?: InputMaybe<Scalars['String']>;
  /** Region/County */
  region?: InputMaybe<Scalars['String']>;
};

export type Asset = Node & {
  __typename?: 'Asset';
  _id: Scalars['String'];
  course?: Maybe<Course>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  fileType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  roles: Scalars['Iterable'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

export type AssetFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AssignedTrainingClass = Node & {
  __typename?: 'AssignedTrainingClass';
  attendee: Scalars['Iterable'];
  deliveryAddress: Address;
  deliveryFormat?: Maybe<DeliveryFormat>;
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  id: Scalars['ID'];
  postCourseFeedbackUrl: Scalars['String'];
  preCourseEnrollmentUrl: Scalars['String'];
  sessions: Scalars['Iterable'];
  startsAt: Scalars['String'];
  title: Scalars['String'];
  trainerName: Scalars['String'];
  trainingFiles: Scalars['Iterable'];
};

/** Attendance Enum */
export enum Attendance {
  Full = 'full',
  None = 'none',
  Partial = 'partial'
}

export type Attendee = Node & {
  __typename?: 'Attendee';
  _id?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customerContact?: Maybe<CustomerContact>;
  hash: Scalars['String'];
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  pdfUrl: Scalars['String'];
  postCourseFeedbackUrl: Scalars['String'];
  postCourseQuestionaireAnswer?: Maybe<QuestionaireAnswer>;
  preCourseEnrollmentUrl: Scalars['String'];
  preCourseQuestionaireAnswer?: Maybe<QuestionaireAnswer>;
  receiveNewsAndUpdates?: Maybe<Scalars['Boolean']>;
  reservation?: Maybe<TrainingSeatReservation>;
  reviewedOutline?: Maybe<Scalars['Iterable']>;
  totalAttendance: Attendance;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  useInMarketing?: Maybe<Scalars['Boolean']>;
};

/** Cursor connection for Attendee. */
export type AttendeeCursorConnection = {
  __typename?: 'AttendeeCursorConnection';
  edges?: Maybe<Array<Maybe<AttendeeEdge>>>;
  pageInfo: AttendeePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Attendee. */
export type AttendeeEdge = {
  __typename?: 'AttendeeEdge';
  cursor: Scalars['String'];
  node?: Maybe<Attendee>;
};

export type AttendeeFilter_Order = {
  reservation_trainingSeats_trainingSession_startsAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type AttendeePageInfo = {
  __typename?: 'AttendeePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Booking = Node & {
  __typename?: 'Booking';
  _id: Scalars['String'];
  accountManager?: Maybe<User>;
  billingAddress: Address;
  billingContact?: Maybe<CustomerContact>;
  bookedAt: Scalars['String'];
  booker?: Maybe<CustomerContact>;
  bookingCustomerContacts?: Maybe<BookingCustomerContactCursorConnection>;
  brand: Brand;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  eventLogs?: Maybe<Array<Maybe<BookingEventLog>>>;
  expectedTotalValue: Money;
  grossPrice: Money;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<BookingItemCursorConnection>;
  itemsTotalNetPrice: Money;
  legacyId?: Maybe<Scalars['String']>;
  netPrice: Money;
  notes?: Maybe<BookingNoteCursorConnection>;
  onlyTaxInvoiceBillable: Scalars['Boolean'];
  paymentTermDays: Scalars['Int'];
  preferredPaymentMethod: PaymentMethod;
  purchaseOrders?: Maybe<PurchaseOrderCursorConnection>;
  reference: Scalars['String'];
  status: BookingStatus;
  taxValue: Money;
  tickets?: Maybe<TicketCursorConnection>;
  ticketsCount: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  webBookingReference?: Maybe<Scalars['String']>;
};


export type BookingBookingCustomerContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type BookingItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<BookingItemFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingItemFilter_Order>>>;
};


export type BookingNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingNoteFilter_Order>>>;
};


export type BookingPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<PurchaseOrderFilter_Order>>>;
  reference?: InputMaybe<Scalars['String']>;
};


export type BookingTicketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  booking?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TicketFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Cursor connection for Booking. */
export type BookingCursorConnection = {
  __typename?: 'BookingCursorConnection';
  edges?: Maybe<Array<Maybe<BookingEdge>>>;
  pageInfo: BookingPageInfo;
  totalCount: Scalars['Int'];
};

export type BookingCustomerContact = Node & {
  __typename?: 'BookingCustomerContact';
  booking?: Maybe<Booking>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customerContact?: Maybe<CustomerContact>;
  id: Scalars['ID'];
  roles?: Maybe<Scalars['Iterable']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for BookingCustomerContact. */
export type BookingCustomerContactCursorConnection = {
  __typename?: 'BookingCustomerContactCursorConnection';
  edges?: Maybe<Array<Maybe<BookingCustomerContactEdge>>>;
  pageInfo: BookingCustomerContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of BookingCustomerContact. */
export type BookingCustomerContactEdge = {
  __typename?: 'BookingCustomerContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<BookingCustomerContact>;
};

/** Information about the current page. */
export type BookingCustomerContactPageInfo = {
  __typename?: 'BookingCustomerContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Edge of Booking. */
export type BookingEdge = {
  __typename?: 'BookingEdge';
  cursor: Scalars['String'];
  node?: Maybe<Booking>;
};

export type BookingEventLog = Node & {
  __typename?: 'BookingEventLog';
  booking?: Maybe<Booking>;
  bookingItem?: Maybe<BookingItem>;
  createdAt: Scalars['String'];
  eventName: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type BookingFilter_CreatedAt = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

export type BookingFilter_Order = {
  accountManager_person_fullName?: InputMaybe<Scalars['String']>;
  bookedAt?: InputMaybe<Scalars['String']>;
  brand_name?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  expectedTotalValue_amount?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BookingItem = Node & {
  __typename?: 'BookingItem';
  _id: Scalars['String'];
  assignedSeatNumber?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Scalars['Iterable']>;
  booking?: Maybe<Booking>;
  canceled: Scalars['Boolean'];
  changeRequests?: Maybe<ChangeRequestCursorConnection>;
  class?: Maybe<TrainingClass>;
  course?: Maybe<CourseVersion>;
  courseVersion: CourseVersion;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['String']>;
  deliveryAddress: Address;
  deliveryFormat?: Maybe<DeliveryFormat>;
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  description?: Maybe<Scalars['String']>;
  firstSessionStartDate?: Maybe<Scalars['String']>;
  grossPrice: Money;
  id: Scalars['ID'];
  invoiceNotes?: Maybe<Scalars['String']>;
  invoicedNetTotal: Money;
  invoices?: Maybe<InvoiceCursorConnection>;
  itemNumber: Scalars['Int'];
  latestInvoice?: Maybe<Invoice>;
  maxAttendees?: Maybe<Scalars['Int']>;
  netPrice: Money;
  poNumber: Scalars['String'];
  public: Scalars['Boolean'];
  quantity: Scalars['String'];
  sessions: Scalars['Iterable'];
  status: BookingItemStatus;
  tailored: Scalars['Boolean'];
  taxRatePercent: Scalars['String'];
  taxValue: Money;
  taxable: Scalars['Boolean'];
  title: Scalars['String'];
  trainer?: Maybe<Trainer>;
  trainerDayRate?: Maybe<Money>;
  trainerDayRateAmount?: Maybe<Scalars['Int']>;
  trainerTravel?: Maybe<Money>;
  trainerTravelAmount?: Maybe<Scalars['Int']>;
  trainingSeatReservations?: Maybe<TrainingSeatReservationCursorConnection>;
  type: Scalars['String'];
  unitPrice: Money;
  unitPriceAmount: Scalars['Int'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type BookingItemChangeRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Array<InputMaybe<ChangeRequestFilter_CreatedAt>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ChangeRequestFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BookingItemInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InvoiceFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BookingItemTrainingSeatReservationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for BookingItem. */
export type BookingItemCursorConnection = {
  __typename?: 'BookingItemCursorConnection';
  edges?: Maybe<Array<Maybe<BookingItemEdge>>>;
  pageInfo: BookingItemPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of BookingItem. */
export type BookingItemEdge = {
  __typename?: 'BookingItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<BookingItem>;
};

export type BookingItemFilter_Exists = {
  deletedAt?: InputMaybe<Scalars['Boolean']>;
};

export type BookingItemFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type BookingItemPageInfo = {
  __typename?: 'BookingItemPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** BookingItemStatus Enum */
export enum BookingItemStatus {
  Canceled = 'canceled',
  Complete = 'complete',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Draft = 'draft'
}

export type BookingNote = Node & {
  __typename?: 'BookingNote';
  body: Scalars['String'];
  booking?: Maybe<Booking>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for BookingNote. */
export type BookingNoteCursorConnection = {
  __typename?: 'BookingNoteCursorConnection';
  edges?: Maybe<Array<Maybe<BookingNoteEdge>>>;
  pageInfo: BookingNotePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of BookingNote. */
export type BookingNoteEdge = {
  __typename?: 'BookingNoteEdge';
  cursor: Scalars['String'];
  node?: Maybe<BookingNote>;
};

export type BookingNoteFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type BookingNotePageInfo = {
  __typename?: 'BookingNotePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type BookingPageInfo = {
  __typename?: 'BookingPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** BookingStatus Enum */
export enum BookingStatus {
  Canceled = 'canceled',
  Complete = 'complete',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Draft = 'draft',
  OnHold = 'on_hold'
}

export type Brand = Node & {
  __typename?: 'Brand';
  _id: Scalars['String'];
  address: Address;
  bookings?: Maybe<BookingCursorConnection>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};


export type BrandBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Array<InputMaybe<BookingFilter_CreatedAt>>>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingFilter_Order>>>;
};

/** Cursor connection for Brand. */
export type BrandCursorConnection = {
  __typename?: 'BrandCursorConnection';
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  pageInfo: BrandPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Brand. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  cursor: Scalars['String'];
  node?: Maybe<Brand>;
};

export type BrandFilter_Order = {
  name?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type BrandPageInfo = {
  __typename?: 'BrandPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ChangeRequest = Node & {
  __typename?: 'ChangeRequest';
  _id: Scalars['String'];
  actions?: Maybe<ChangeRequestActionCursorConnection>;
  bookingItem: BookingItem;
  canceledAt?: Maybe<Scalars['String']>;
  canceledBy?: Maybe<User>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  deliveryAddress: Address;
  deliveryFormat?: Maybe<DeliveryFormat>;
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  id: Scalars['ID'];
  implementedAt?: Maybe<Scalars['String']>;
  implementedBy?: Maybe<User>;
  maxAttendees?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['String']>;
  rejectedBy?: Maybe<User>;
  rejectedReason?: Maybe<Scalars['String']>;
  sessions?: Maybe<Scalars['Iterable']>;
  status: ChangeRequestStatus;
  trainer?: Maybe<Trainer>;
  trainerDayRate?: Maybe<Money>;
  trainerDayRateAmount?: Maybe<Scalars['Int']>;
  trainerTravel?: Maybe<Money>;
  trainerTravelAmount?: Maybe<Scalars['Int']>;
  trainingClass?: Maybe<TrainingClass>;
  unitPrice?: Maybe<Money>;
  unitPriceAmount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type ChangeRequestActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ChangeRequestAction = Node & {
  __typename?: 'ChangeRequestAction';
  ChangeRequest?: Maybe<ChangeRequest>;
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  errors?: Maybe<Scalars['Iterable']>;
  id: Scalars['ID'];
  lastRanAt?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['Iterable']>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for ChangeRequestAction. */
export type ChangeRequestActionCursorConnection = {
  __typename?: 'ChangeRequestActionCursorConnection';
  edges?: Maybe<Array<Maybe<ChangeRequestActionEdge>>>;
  pageInfo: ChangeRequestActionPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ChangeRequestAction. */
export type ChangeRequestActionEdge = {
  __typename?: 'ChangeRequestActionEdge';
  cursor: Scalars['String'];
  node?: Maybe<ChangeRequestAction>;
};

/** Information about the current page. */
export type ChangeRequestActionPageInfo = {
  __typename?: 'ChangeRequestActionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for ChangeRequest. */
export type ChangeRequestCursorConnection = {
  __typename?: 'ChangeRequestCursorConnection';
  edges?: Maybe<Array<Maybe<ChangeRequestEdge>>>;
  pageInfo: ChangeRequestPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of ChangeRequest. */
export type ChangeRequestEdge = {
  __typename?: 'ChangeRequestEdge';
  cursor: Scalars['String'];
  node?: Maybe<ChangeRequest>;
};

export type ChangeRequestFilter_CreatedAt = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

export type ChangeRequestFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type ChangeRequestPageInfo = {
  __typename?: 'ChangeRequestPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** ChangeRequestStatus Enum */
export enum ChangeRequestStatus {
  Canceled = 'canceled',
  Errored = 'errored',
  Implemented = 'implemented',
  Pending = 'pending',
  Rejected = 'rejected'
}

export type Course = Node & {
  __typename?: 'Course';
  _id: Scalars['String'];
  assets?: Maybe<Array<Maybe<Asset>>>;
  coursesVersions?: Maybe<CourseVersionCursorConnection>;
  createdAt: Scalars['String'];
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latestCourseVersion?: Maybe<CourseVersion>;
  legacyId?: Maybe<Scalars['String']>;
  publishedCourseVersion?: Maybe<CourseVersion>;
  status: Scalars['String'];
  trainerCourses?: Maybe<TrainerCourseCursorConnection>;
  updatedAt: Scalars['String'];
};


export type CourseAssetsArgs = {
  course?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetFilter_Order>>>;
};


export type CourseCoursesVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<CourseVersionFilter_Order>>>;
};


export type CourseTrainerCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  course_publishedCourseVersion_title?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Order>>>;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Cursor connection for Course. */
export type CourseCursorConnection = {
  __typename?: 'CourseCursorConnection';
  edges?: Maybe<Array<Maybe<CourseEdge>>>;
  pageInfo: CoursePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Course. */
export type CourseEdge = {
  __typename?: 'CourseEdge';
  cursor: Scalars['String'];
  node?: Maybe<Course>;
};

export type CourseFilter_Exists = {
  publishedCourseVersion?: InputMaybe<Scalars['Boolean']>;
};

export type CourseFilter_Order = {
  coursesVersions_createdAt?: InputMaybe<Scalars['String']>;
  latestCourseVersion_durationDays?: InputMaybe<Scalars['String']>;
  latestCourseVersion_level?: InputMaybe<Scalars['String']>;
  latestCourseVersion_subject_title?: InputMaybe<Scalars['String']>;
  latestCourseVersion_title?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type CoursePageInfo = {
  __typename?: 'CoursePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CourseVersion = Node & {
  __typename?: 'CourseVersion';
  _id: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  authorReference?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  durationDays: Scalars['Float'];
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  outline: Scalars['Iterable'];
  prerequisites?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedBy?: Maybe<User>;
  reference: Scalars['String'];
  revision: Scalars['Int'];
  setupRequirements?: Maybe<Scalars['String']>;
  subject?: Maybe<Subject>;
  title: Scalars['String'];
  trainingClasses?: Maybe<TrainingClassCursorConnection>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type CourseVersionTrainingClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingClassFilter_Order>>>;
  sessions_seats_trainingSeatReservation_attendee_customerContact?: InputMaybe<Scalars['String']>;
};

/** Cursor connection for CourseVersion. */
export type CourseVersionCursorConnection = {
  __typename?: 'CourseVersionCursorConnection';
  edges?: Maybe<Array<Maybe<CourseVersionEdge>>>;
  pageInfo: CourseVersionPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CourseVersion. */
export type CourseVersionEdge = {
  __typename?: 'CourseVersionEdge';
  cursor: Scalars['String'];
  node?: Maybe<CourseVersion>;
};

export type CourseVersionFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type CourseVersionPageInfo = {
  __typename?: 'CourseVersionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Customer = Node & {
  __typename?: 'Customer';
  _id: Scalars['String'];
  accountManager?: Maybe<User>;
  bookings?: Maybe<BookingCursorConnection>;
  bookingsCount: Scalars['Int'];
  contacts?: Maybe<CustomerContactCursorConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  locations?: Maybe<CustomerLocationCursorConnection>;
  name: Scalars['String'];
  notes?: Maybe<CustomerNoteCursorConnection>;
  onlyTaxInvoiceBillable: Scalars['Boolean'];
  paymentTermDays: Scalars['Int'];
  preferredPaymentMethod: PaymentMethod;
  reference: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: OrganisationType;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  vatNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};


export type CustomerBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Array<InputMaybe<BookingFilter_CreatedAt>>>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingFilter_Order>>>;
};


export type CustomerContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  booking?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Scalars['Iterable']>;
};


export type CustomerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CustomerNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<CustomerNoteFilter_Order>>>;
};

export type CustomerContact = Node & {
  __typename?: 'CustomerContact';
  _id: Scalars['String'];
  bookingCustomerContacts?: Maybe<BookingCustomerContactCursorConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  telephone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};


export type CustomerContactBookingCustomerContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for CustomerContact. */
export type CustomerContactCursorConnection = {
  __typename?: 'CustomerContactCursorConnection';
  edges?: Maybe<Array<Maybe<CustomerContactEdge>>>;
  pageInfo: CustomerContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CustomerContact. */
export type CustomerContactEdge = {
  __typename?: 'CustomerContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomerContact>;
};

/** Information about the current page. */
export type CustomerContactPageInfo = {
  __typename?: 'CustomerContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Customer. */
export type CustomerCursorConnection = {
  __typename?: 'CustomerCursorConnection';
  edges?: Maybe<Array<Maybe<CustomerEdge>>>;
  pageInfo: CustomerPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Customer. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Customer>;
};

export type CustomerFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CustomerLocation = Node & {
  __typename?: 'CustomerLocation';
  _id: Scalars['String'];
  address: Address;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for CustomerLocation. */
export type CustomerLocationCursorConnection = {
  __typename?: 'CustomerLocationCursorConnection';
  edges?: Maybe<Array<Maybe<CustomerLocationEdge>>>;
  pageInfo: CustomerLocationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CustomerLocation. */
export type CustomerLocationEdge = {
  __typename?: 'CustomerLocationEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomerLocation>;
};

/** Information about the current page. */
export type CustomerLocationPageInfo = {
  __typename?: 'CustomerLocationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type CustomerNote = Node & {
  __typename?: 'CustomerNote';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  customer?: Maybe<Customer>;
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for CustomerNote. */
export type CustomerNoteCursorConnection = {
  __typename?: 'CustomerNoteCursorConnection';
  edges?: Maybe<Array<Maybe<CustomerNoteEdge>>>;
  pageInfo: CustomerNotePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of CustomerNote. */
export type CustomerNoteEdge = {
  __typename?: 'CustomerNoteEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomerNote>;
};

export type CustomerNoteFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type CustomerNotePageInfo = {
  __typename?: 'CustomerNotePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type CustomerPageInfo = {
  __typename?: 'CustomerPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Debtor = Node & {
  __typename?: 'Debtor';
  age: Scalars['Int'];
  booking: Booking;
  customer?: Maybe<Customer>;
  dueDate: Scalars['String'];
  gross: Money;
  id: Scalars['ID'];
  invoice: Invoice;
  poNumber?: Maybe<Scalars['String']>;
  taxDate: Scalars['String'];
};

export type DebtorTotalGross = Node & {
  __typename?: 'DebtorTotalGross';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
  value: Money;
};

/** DeliveryFormat Enum */
export enum DeliveryFormat {
  OnSite = 'on_site',
  TrainingCentre = 'training_centre',
  Virtual = 'virtual'
}

/** DeliveryPlatform Enum */
export enum DeliveryPlatform {
  MsTeams = 'ms_teams',
  Other = 'other',
  Zoom = 'zoom'
}

export type Email = Node & {
  __typename?: 'Email';
  _id: Scalars['String'];
  bcc: Scalars['Iterable'];
  buildContext?: Maybe<EmailBuildContext>;
  cc: Scalars['Iterable'];
  data: Scalars['Iterable'];
  emailTemplate?: Maybe<EmailTemplate>;
  from: Scalars['Iterable'];
  fromContact?: Maybe<InternalContact>;
  htmlBody: Scalars['String'];
  id: Scalars['ID'];
  lastSentAt?: Maybe<Scalars['String']>;
  logs?: Maybe<EmailLogCursorConnection>;
  subject: Scalars['String'];
  targetContact?: Maybe<Person>;
  templateDesign: Scalars['String'];
  templateHtml: Scalars['String'];
  textBody: Scalars['String'];
  to: Scalars['Iterable'];
  toBeSent: Scalars['Boolean'];
};


export type EmailLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EmailLogFilter_Order>>>;
  to?: InputMaybe<Scalars['String']>;
};

export type EmailBuildContext = Node & {
  __typename?: 'EmailBuildContext';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  emailTemplate?: Maybe<EmailTemplate>;
  emails?: Maybe<EmailCursorConnection>;
  forceResend: Scalars['Boolean'];
  id: Scalars['ID'];
  sentAt?: Maybe<Scalars['String']>;
  targetContacts?: Maybe<Scalars['Iterable']>;
  targetResource: TargetResource;
  targetResourceId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type EmailBuildContextEmailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for EmailBuildContext. */
export type EmailBuildContextCursorConnection = {
  __typename?: 'EmailBuildContextCursorConnection';
  edges?: Maybe<Array<Maybe<EmailBuildContextEdge>>>;
  pageInfo: EmailBuildContextPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of EmailBuildContext. */
export type EmailBuildContextEdge = {
  __typename?: 'EmailBuildContextEdge';
  cursor: Scalars['String'];
  node?: Maybe<EmailBuildContext>;
};

export type EmailBuildContextFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type EmailBuildContextPageInfo = {
  __typename?: 'EmailBuildContextPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Email. */
export type EmailCursorConnection = {
  __typename?: 'EmailCursorConnection';
  edges?: Maybe<Array<Maybe<EmailEdge>>>;
  pageInfo: EmailPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Email. */
export type EmailEdge = {
  __typename?: 'EmailEdge';
  cursor: Scalars['String'];
  node?: Maybe<Email>;
};

/** EmailEditor Enum */
export enum EmailEditor {
  Html = 'html',
  Unlayer = 'unlayer'
}

export type EmailLog = Node & {
  __typename?: 'EmailLog';
  _id: Scalars['String'];
  bcc: Scalars['String'];
  cc: Scalars['String'];
  createdAt: Scalars['String'];
  email?: Maybe<Email>;
  from: Scalars['String'];
  htmlBody: Scalars['String'];
  id: Scalars['ID'];
  person?: Maybe<Person>;
  subject: Scalars['String'];
  textBody: Scalars['String'];
  to: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Cursor connection for EmailLog. */
export type EmailLogCursorConnection = {
  __typename?: 'EmailLogCursorConnection';
  edges?: Maybe<Array<Maybe<EmailLogEdge>>>;
  pageInfo: EmailLogPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of EmailLog. */
export type EmailLogEdge = {
  __typename?: 'EmailLogEdge';
  cursor: Scalars['String'];
  node?: Maybe<EmailLog>;
};

export type EmailLogFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type EmailLogPageInfo = {
  __typename?: 'EmailLogPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type EmailPageInfo = {
  __typename?: 'EmailPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type EmailSampleData = Node & {
  __typename?: 'EmailSampleData';
  data: Scalars['String'];
  id: Scalars['ID'];
  resourceName: Scalars['String'];
};

export type EmailTemplate = Node & {
  __typename?: 'EmailTemplate';
  _id: Scalars['String'];
  actions?: Maybe<WorkflowActionCursorConnection>;
  brand?: Maybe<Brand>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  design: Scalars['String'];
  editor: EmailEditor;
  html: Scalars['String'];
  id: Scalars['ID'];
  loadFileName?: Maybe<Scalars['String']>;
  loadedAt?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reference: Scalars['String'];
  subject: Scalars['String'];
  targetContactCondition?: Maybe<Scalars['String']>;
  targetContactTypes: Scalars['Iterable'];
  targetResource: TargetResource;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type EmailTemplateActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for EmailTemplate. */
export type EmailTemplateCursorConnection = {
  __typename?: 'EmailTemplateCursorConnection';
  edges?: Maybe<Array<Maybe<EmailTemplateEdge>>>;
  pageInfo: EmailTemplatePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of EmailTemplate. */
export type EmailTemplateEdge = {
  __typename?: 'EmailTemplateEdge';
  cursor: Scalars['String'];
  node?: Maybe<EmailTemplate>;
};

export type EmailTemplateFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type EmailTemplatePageInfo = {
  __typename?: 'EmailTemplatePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type EmailTemplatePreview = Node & {
  __typename?: 'EmailTemplatePreview';
  data: Scalars['String'];
  errors: Scalars['Iterable'];
  html: Scalars['String'];
  id: Scalars['ID'];
  template: Scalars['String'];
};

export type File = Node & {
  __typename?: 'File';
  _id: Scalars['String'];
  current?: Maybe<FileVersion>;
  downloadUrl: Scalars['String'];
  id: Scalars['ID'];
  showUrl: Scalars['String'];
  versions?: Maybe<Array<Maybe<FileVersion>>>;
};

/** Cursor connection for File. */
export type FileCursorConnection = {
  __typename?: 'FileCursorConnection';
  edges?: Maybe<Array<Maybe<FileEdge>>>;
  pageInfo: FilePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of File. */
export type FileEdge = {
  __typename?: 'FileEdge';
  cursor: Scalars['String'];
  node?: Maybe<File>;
};

/** Information about the current page. */
export type FilePageInfo = {
  __typename?: 'FilePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type FileVersion = Node & {
  __typename?: 'FileVersion';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  downloadUrl: Scalars['String'];
  file?: Maybe<File>;
  fileName: Scalars['String'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  originalFileName: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  revision: Scalars['Int'];
  size: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type InternalContact = Node & {
  __typename?: 'InternalContact';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

/** Cursor connection for InternalContact. */
export type InternalContactCursorConnection = {
  __typename?: 'InternalContactCursorConnection';
  edges?: Maybe<Array<Maybe<InternalContactEdge>>>;
  pageInfo: InternalContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of InternalContact. */
export type InternalContactEdge = {
  __typename?: 'InternalContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<InternalContact>;
};

/** Information about the current page. */
export type InternalContactPageInfo = {
  __typename?: 'InternalContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  _id?: Maybe<Scalars['String']>;
  activityLogs?: Maybe<InvoiceActivityLogCursorConnection>;
  billingAddress: Address;
  bookingItem?: Maybe<BookingItem>;
  brand?: Maybe<Brand>;
  children?: Maybe<InvoiceCursorConnection>;
  createdAt: Scalars['String'];
  customer?: Maybe<Customer>;
  documentDate: Scalars['String'];
  dueDate: Scalars['String'];
  eventLogs?: Maybe<Array<Maybe<InvoiceEventLog>>>;
  grossPrice: Money;
  id: Scalars['ID'];
  items?: Maybe<InvoiceItemCursorConnection>;
  netPrice: Money;
  notes?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  parent?: Maybe<Invoice>;
  payments?: Maybe<InvoicePaymentCursorConnection>;
  paymentsTotal: Money;
  pdfUrl: Scalars['String'];
  poNumber: Scalars['String'];
  reference: Scalars['String'];
  remainingBalance: Money;
  status: InvoiceStatus;
  taxValue: Money;
  title?: Maybe<Scalars['String']>;
  type: InvoiceType;
  updatedAt: Scalars['String'];
  vatNumber?: Maybe<Scalars['String']>;
  voidedAt?: Maybe<Scalars['String']>;
  voidedBy?: Maybe<User>;
  xeroEditUrl?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};


export type InvoiceActivityLogsArgs = {
  action?: InputMaybe<Scalars['String']>;
  action_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InvoiceActivityLogFilter_Order>>>;
  user_roles?: InputMaybe<Scalars['Iterable']>;
};


export type InvoiceChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InvoiceFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type InvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type InvoicePaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type InvoiceActivityLog = Node & {
  __typename?: 'InvoiceActivityLog';
  _id: Scalars['String'];
  action: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  metadata: Scalars['Iterable'];
  updatedAt: Scalars['String'];
  user?: Maybe<User>;
};

/** Cursor connection for InvoiceActivityLog. */
export type InvoiceActivityLogCursorConnection = {
  __typename?: 'InvoiceActivityLogCursorConnection';
  edges?: Maybe<Array<Maybe<InvoiceActivityLogEdge>>>;
  pageInfo: InvoiceActivityLogPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of InvoiceActivityLog. */
export type InvoiceActivityLogEdge = {
  __typename?: 'InvoiceActivityLogEdge';
  cursor: Scalars['String'];
  node?: Maybe<InvoiceActivityLog>;
};

export type InvoiceActivityLogFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type InvoiceActivityLogPageInfo = {
  __typename?: 'InvoiceActivityLogPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Invoice. */
export type InvoiceCursorConnection = {
  __typename?: 'InvoiceCursorConnection';
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  pageInfo: InvoicePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Invoice. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Invoice>;
};

export type InvoiceEventLog = Node & {
  __typename?: 'InvoiceEventLog';
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  eventName: Scalars['String'];
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  metaData: Scalars['Iterable'];
  updatedAt: Scalars['String'];
};

export type InvoiceFilter_Order = {
  bookingItem_class_title?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  documentDate?: InputMaybe<Scalars['String']>;
  netPriceAmount?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type InvoiceItem = Node & {
  __typename?: 'InvoiceItem';
  account?: Maybe<Account>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  grossPrice: Money;
  id: Scalars['ID'];
  invoice?: Maybe<Invoice>;
  netPrice: Money;
  quantity: Scalars['String'];
  taxRatePercent: Scalars['String'];
  taxValue: Money;
  taxable: Scalars['Boolean'];
  unitPrice: Money;
  unitPriceAmount: Scalars['Int'];
  updatedAt: Scalars['String'];
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};

/** Cursor connection for InvoiceItem. */
export type InvoiceItemCursorConnection = {
  __typename?: 'InvoiceItemCursorConnection';
  edges?: Maybe<Array<Maybe<InvoiceItemEdge>>>;
  pageInfo: InvoiceItemPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of InvoiceItem. */
export type InvoiceItemEdge = {
  __typename?: 'InvoiceItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<InvoiceItem>;
};

/** Information about the current page. */
export type InvoiceItemPageInfo = {
  __typename?: 'InvoiceItemPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type InvoiceNestedInput = {
  billingAddress: AddressInput;
  bookingItem?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  children?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  createdAt: Scalars['String'];
  customer?: InputMaybe<Scalars['String']>;
  documentDate: Scalars['String'];
  dueDate: Scalars['String'];
  eventLogs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['ID']>;
  items?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notes?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['String']>;
  payments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  poNumber: Scalars['String'];
  reference: Scalars['String'];
  status: InvoiceStatus;
  type: InvoiceType;
  updatedAt: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
  voidedAt?: InputMaybe<Scalars['String']>;
  voidedBy?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type InvoicePageInfo = {
  __typename?: 'InvoicePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type InvoicePayment = Node & {
  __typename?: 'InvoicePayment';
  _id?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  amountAsMoney: Money;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  invoice: Invoice;
  notes?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  receivedAt: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};

/** Cursor connection for InvoicePayment. */
export type InvoicePaymentCursorConnection = {
  __typename?: 'InvoicePaymentCursorConnection';
  edges?: Maybe<Array<Maybe<InvoicePaymentEdge>>>;
  pageInfo: InvoicePaymentPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of InvoicePayment. */
export type InvoicePaymentEdge = {
  __typename?: 'InvoicePaymentEdge';
  cursor: Scalars['String'];
  node?: Maybe<InvoicePayment>;
};

/** Information about the current page. */
export type InvoicePaymentPageInfo = {
  __typename?: 'InvoicePaymentPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** InvoiceStatus Enum */
export enum InvoiceStatus {
  Confirmed = 'confirmed',
  Converted = 'converted',
  Draft = 'draft',
  Paid = 'paid',
  PaidOverpaid = 'paid_overpaid',
  PaidPartial = 'paid_partial',
  Voided = 'voided'
}

/** InvoiceType Enum */
export enum InvoiceType {
  CreditNote = 'credit_note',
  Invoice = 'invoice',
  ProForma = 'pro_forma'
}

export type Location = Node & {
  __typename?: 'Location';
  _id: Scalars['String'];
  address: Address;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for Location. */
export type LocationCursorConnection = {
  __typename?: 'LocationCursorConnection';
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  pageInfo: LocationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Location. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Location>;
};

/** Information about the current page. */
export type LocationPageInfo = {
  __typename?: 'LocationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** The `Money` scalar type represents money data. */
export type Money = {
  __typename?: 'Money';
  /** The amount of money */
  amount: Scalars['Int'];
  /** The currency of the money */
  currency: Scalars['String'];
  /** The amount of money as decimal */
  decimal: Scalars['String'];
  /** The formatted amount of money */
  formatted: Scalars['String'];
};

export type MoneyInput = {
  /** The amount of money */
  amount: Scalars['Int'];
  /** The currency of the money */
  currency?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Acknowledges a PurchaseOrder. */
  acknowledgePurchaseOrder?: Maybe<AcknowledgePurchaseOrderPayload>;
  /** AssignAttendeess a TrainingClass. */
  assignAttendeesTrainingClass?: Maybe<AssignAttendeesTrainingClassPayload>;
  /** AssignCustomerContacts a Booking. */
  assignCustomerContactBooking?: Maybe<AssignCustomerContactBookingPayload>;
  /** Assigns a TrainingSeat. */
  assignTrainingSeat?: Maybe<AssignTrainingSeatPayload>;
  /** Builds a EmailBuildContext. */
  buildEmailBuildContext?: Maybe<BuildEmailBuildContextPayload>;
  /** Cancels a BookingItem. */
  cancelBookingItem?: Maybe<CancelBookingItemPayload>;
  /** Cancels a ChangeRequest. */
  cancelChangeRequest?: Maybe<CancelChangeRequestPayload>;
  /** Cancels a PublicSchedule. */
  cancelPublicSchedule?: Maybe<CancelPublicSchedulePayload>;
  /** Confirms a Booking. */
  confirmBooking?: Maybe<ConfirmBookingPayload>;
  /** ConvertToTaxInvoices a Invoice. */
  convertToTaxInvoiceInvoice?: Maybe<ConvertToTaxInvoiceInvoicePayload>;
  /** Creates a Account. */
  createAccount?: Maybe<CreateAccountPayload>;
  /** Creates a Attendee. */
  createAttendee?: Maybe<CreateAttendeePayload>;
  /** Creates a Booking. */
  createBooking?: Maybe<CreateBookingPayload>;
  /** Creates a BookingItem. */
  createBookingItem?: Maybe<CreateBookingItemPayload>;
  /** Creates a BookingNote. */
  createBookingNote?: Maybe<CreateBookingNotePayload>;
  /** Creates a ChangeRequest. */
  createChangeRequest?: Maybe<CreateChangeRequestPayload>;
  /** Creates a CourseVersion. */
  createCourseVersion?: Maybe<CreateCourseVersionPayload>;
  /** Creates a Customer. */
  createCustomer?: Maybe<CreateCustomerPayload>;
  /** Creates a CustomerContact. */
  createCustomerContact?: Maybe<CreateCustomerContactPayload>;
  /** Creates a CustomerLocation. */
  createCustomerLocation?: Maybe<CreateCustomerLocationPayload>;
  /** Creates a CustomerNote. */
  createCustomerNote?: Maybe<CreateCustomerNotePayload>;
  /** Creates a EmailBuildContext. */
  createEmailBuildContext?: Maybe<CreateEmailBuildContextPayload>;
  /** Creates a EmailTemplate. */
  createEmailTemplate?: Maybe<CreateEmailTemplatePayload>;
  /** Creates a File. */
  createFile?: Maybe<CreateFilePayload>;
  /** Creates a FileVersion. */
  createFileVersion?: Maybe<CreateFileVersionPayload>;
  /** Creates a InternalContact. */
  createInternalContact?: Maybe<CreateInternalContactPayload>;
  /** Creates a InvoiceEventLog. */
  createInvoiceEventLog?: Maybe<CreateInvoiceEventLogPayload>;
  /** Creates a InvoiceItem. */
  createInvoiceItem?: Maybe<CreateInvoiceItemPayload>;
  /** Creates a InvoicePayment. */
  createInvoicePayment?: Maybe<CreateInvoicePaymentPayload>;
  /** Creates a Location. */
  createLocation?: Maybe<CreateLocationPayload>;
  /** Creates a NewPublicScheduleDates. */
  createNewPublicScheduleDates?: Maybe<CreateNewPublicScheduleDatesPayload>;
  /** Creates a NewSupplier. */
  createNewSupplier?: Maybe<CreateNewSupplierPayload>;
  /** Creates a NewTrainingClass. */
  createNewTrainingClass?: Maybe<CreateNewTrainingClassPayload>;
  /** Creates a NewUser. */
  createNewUser?: Maybe<CreateNewUserPayload>;
  /** Creates a Organisation. */
  createOrganisation?: Maybe<CreateOrganisationPayload>;
  /** Creates a OrganisationType. */
  createOrganisationType?: Maybe<CreateOrganisationTypePayload>;
  /** Creates a Parameter. */
  createParameter?: Maybe<CreateParameterPayload>;
  /** Creates a Person. */
  createPerson?: Maybe<CreatePersonPayload>;
  /** CreatePublicCourses a Booking. */
  createPublicCourseBooking?: Maybe<CreatePublicCourseBookingPayload>;
  /** Creates a PurchaseOrderEvent. */
  createPurchaseOrderEvent?: Maybe<CreatePurchaseOrderEventPayload>;
  /** Creates a PurchaseOrderItem. */
  createPurchaseOrderItem?: Maybe<CreatePurchaseOrderItemPayload>;
  /** CreatePurchaseOrders a PublicSchedule. */
  createPurchaseOrderPublicSchedule?: Maybe<CreatePurchaseOrderPublicSchedulePayload>;
  /** Creates a Subject. */
  createSubject?: Maybe<CreateSubjectPayload>;
  /** Creates a Supplier. */
  createSupplier?: Maybe<CreateSupplierPayload>;
  /** Creates a SupplierContact. */
  createSupplierContact?: Maybe<CreateSupplierContactPayload>;
  /** Creates a SupplierLocation. */
  createSupplierLocation?: Maybe<CreateSupplierLocationPayload>;
  /** Creates a SupplierNote. */
  createSupplierNote?: Maybe<CreateSupplierNotePayload>;
  /** Creates a Ticket. */
  createTicket?: Maybe<CreateTicketPayload>;
  /** Creates a TicketActivity. */
  createTicketActivity?: Maybe<CreateTicketActivityPayload>;
  /** Creates a Trainer. */
  createTrainer?: Maybe<CreateTrainerPayload>;
  /** Creates a TrainerCourse. */
  createTrainerCourse?: Maybe<CreateTrainerCoursePayload>;
  /** Creates a TrainingClassNote. */
  createTrainingClassNote?: Maybe<CreateTrainingClassNotePayload>;
  /** Creates a TrainingSeatReservation. */
  createTrainingSeatReservation?: Maybe<CreateTrainingSeatReservationPayload>;
  /** Creates a User. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a Workflow. */
  createWorkflow?: Maybe<CreateWorkflowPayload>;
  /** Creates a WorkflowAction. */
  createWorkflowAction?: Maybe<CreateWorkflowActionPayload>;
  /** Creates a WorkflowErrorLog. */
  createWorkflowErrorLog?: Maybe<CreateWorkflowErrorLogPayload>;
  /** Deletes a Account. */
  deleteAccount?: Maybe<DeleteAccountPayload>;
  /** Deletes a Booking. */
  deleteBooking?: Maybe<DeleteBookingPayload>;
  /** Deletes a BookingCustomerContact. */
  deleteBookingCustomerContact?: Maybe<DeleteBookingCustomerContactPayload>;
  /** Deletes a BookingItem. */
  deleteBookingItem?: Maybe<DeleteBookingItemPayload>;
  /** Deletes a Course. */
  deleteCourse?: Maybe<DeleteCoursePayload>;
  /** Deletes a CourseVersion. */
  deleteCourseVersion?: Maybe<DeleteCourseVersionPayload>;
  /** Deletes a CustomerLocation. */
  deleteCustomerLocation?: Maybe<DeleteCustomerLocationPayload>;
  /** Deletes a File. */
  deleteFile?: Maybe<DeleteFilePayload>;
  /** Deletes a FileVersion. */
  deleteFileVersion?: Maybe<DeleteFileVersionPayload>;
  /** Deletes a InternalContact. */
  deleteInternalContact?: Maybe<DeleteInternalContactPayload>;
  /** Deletes a InvoiceEventLog. */
  deleteInvoiceEventLog?: Maybe<DeleteInvoiceEventLogPayload>;
  /** Deletes a InvoiceItem. */
  deleteInvoiceItem?: Maybe<DeleteInvoiceItemPayload>;
  /** Deletes a Location. */
  deleteLocation?: Maybe<DeleteLocationPayload>;
  /** Deletes a NewPublicScheduleDates. */
  deleteNewPublicScheduleDates?: Maybe<DeleteNewPublicScheduleDatesPayload>;
  /** Deletes a NewTrainingClass. */
  deleteNewTrainingClass?: Maybe<DeleteNewTrainingClassPayload>;
  /** Deletes a Organisation. */
  deleteOrganisation?: Maybe<DeleteOrganisationPayload>;
  /** Deletes a OrganisationType. */
  deleteOrganisationType?: Maybe<DeleteOrganisationTypePayload>;
  /** Deletes a Parameter. */
  deleteParameter?: Maybe<DeleteParameterPayload>;
  /** Deletes a Person. */
  deletePerson?: Maybe<DeletePersonPayload>;
  /** Deletes a PurchaseOrderEvent. */
  deletePurchaseOrderEvent?: Maybe<DeletePurchaseOrderEventPayload>;
  /** Deletes a PurchaseOrderItem. */
  deletePurchaseOrderItem?: Maybe<DeletePurchaseOrderItemPayload>;
  /** Deletes a SupplierLocation. */
  deleteSupplierLocation?: Maybe<DeleteSupplierLocationPayload>;
  /** Deletes a TrainerCourse. */
  deleteTrainerCourse?: Maybe<DeleteTrainerCoursePayload>;
  /** Deletes a TrainingSeatReservation. */
  deleteTrainingSeatReservation?: Maybe<DeleteTrainingSeatReservationPayload>;
  /** Deletes a Workflow. */
  deleteWorkflow?: Maybe<DeleteWorkflowPayload>;
  /** Deletes a WorkflowAction. */
  deleteWorkflowAction?: Maybe<DeleteWorkflowActionPayload>;
  /** Deletes a WorkflowErrorLog. */
  deleteWorkflowErrorLog?: Maybe<DeleteWorkflowErrorLogPayload>;
  /** Implements a ChangeRequest. */
  implementChangeRequest?: Maybe<ImplementChangeRequestPayload>;
  /** Rejects a ChangeRequest. */
  rejectChangeRequest?: Maybe<RejectChangeRequestPayload>;
  /** Resends a Email. */
  resendEmail?: Maybe<ResendEmailPayload>;
  /** Sends a EmailBuildContext. */
  sendEmailBuildContext?: Maybe<SendEmailBuildContextPayload>;
  /** SubstituteTrainers a BookingItem. */
  substituteTrainerBookingItem?: Maybe<SubstituteTrainerBookingItemPayload>;
  /** SubstituteTrainers a PublicSchedule. */
  substituteTrainerPublicSchedule?: Maybe<SubstituteTrainerPublicSchedulePayload>;
  /** Unassigns a TrainingSeat. */
  unassignTrainingSeat?: Maybe<UnassignTrainingSeatPayload>;
  /** Updates a Account. */
  updateAccount?: Maybe<UpdateAccountPayload>;
  /** UpdateAlls a Email. */
  updateAllEmail?: Maybe<UpdateAllEmailPayload>;
  /** UpdateBillingAddressAndPONumbers a Invoice. */
  updateBillingAddressAndPONumberInvoice?: Maybe<UpdateBillingAddressAndPoNumberInvoicePayload>;
  /** Updates a Booking. */
  updateBooking?: Maybe<UpdateBookingPayload>;
  /** Updates a BookingItem. */
  updateBookingItem?: Maybe<UpdateBookingItemPayload>;
  /** Updates a ChangeRequestAction. */
  updateChangeRequestAction?: Maybe<UpdateChangeRequestActionPayload>;
  /** Updates a Course. */
  updateCourse?: Maybe<UpdateCoursePayload>;
  /** Updates a CourseVersion. */
  updateCourseVersion?: Maybe<UpdateCourseVersionPayload>;
  /** Updates a Customer. */
  updateCustomer?: Maybe<UpdateCustomerPayload>;
  /** Updates a CustomerContact. */
  updateCustomerContact?: Maybe<UpdateCustomerContactPayload>;
  /** Updates a CustomerLocation. */
  updateCustomerLocation?: Maybe<UpdateCustomerLocationPayload>;
  /** UpdateDocumentDateAndNotess a Invoice. */
  updateDocumentDateAndNotesInvoice?: Maybe<UpdateDocumentDateAndNotesInvoicePayload>;
  /** Updates a Email. */
  updateEmail?: Maybe<UpdateEmailPayload>;
  /** Updates a EmailTemplate. */
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates a File. */
  updateFile?: Maybe<UpdateFilePayload>;
  /** Updates a FileVersion. */
  updateFileVersion?: Maybe<UpdateFileVersionPayload>;
  /** Updates a InternalContact. */
  updateInternalContact?: Maybe<UpdateInternalContactPayload>;
  /** Updates a Invoice. */
  updateInvoice?: Maybe<UpdateInvoicePayload>;
  /** Updates a InvoiceEventLog. */
  updateInvoiceEventLog?: Maybe<UpdateInvoiceEventLogPayload>;
  /** Updates a InvoiceItem. */
  updateInvoiceItem?: Maybe<UpdateInvoiceItemPayload>;
  /** Updates a Location. */
  updateLocation?: Maybe<UpdateLocationPayload>;
  /** UpdateMeetingLinks a TrainingSession. */
  updateMeetingLinkTrainingSession?: Maybe<UpdateMeetingLinkTrainingSessionPayload>;
  /** Updates a NewPublicScheduleDates. */
  updateNewPublicScheduleDates?: Maybe<UpdateNewPublicScheduleDatesPayload>;
  /** Updates a NewTrainingClass. */
  updateNewTrainingClass?: Maybe<UpdateNewTrainingClassPayload>;
  /** Updates a Organisation. */
  updateOrganisation?: Maybe<UpdateOrganisationPayload>;
  /** Updates a OrganisationType. */
  updateOrganisationType?: Maybe<UpdateOrganisationTypePayload>;
  /** Updates a Parameter. */
  updateParameter?: Maybe<UpdateParameterPayload>;
  /** Updates a Person. */
  updatePerson?: Maybe<UpdatePersonPayload>;
  /** UpdatePoNumbers a BookingItem. */
  updatePoNumberBookingItem?: Maybe<UpdatePoNumberBookingItemPayload>;
  /** Updates a PostCourseFeedback. */
  updatePostCourseFeedback?: Maybe<UpdatePostCourseFeedbackPayload>;
  /** Updates a PreCourseEnrollment. */
  updatePreCourseEnrollment?: Maybe<UpdatePreCourseEnrollmentPayload>;
  /** Updates a PurchaseOrderEvent. */
  updatePurchaseOrderEvent?: Maybe<UpdatePurchaseOrderEventPayload>;
  /** Updates a PurchaseOrderItem. */
  updatePurchaseOrderItem?: Maybe<UpdatePurchaseOrderItemPayload>;
  /** Updates a Supplier. */
  updateSupplier?: Maybe<UpdateSupplierPayload>;
  /** Updates a SupplierContact. */
  updateSupplierContact?: Maybe<UpdateSupplierContactPayload>;
  /** Updates a SupplierLocation. */
  updateSupplierLocation?: Maybe<UpdateSupplierLocationPayload>;
  /** Updates a Ticket. */
  updateTicket?: Maybe<UpdateTicketPayload>;
  /** Updates a Trainer. */
  updateTrainer?: Maybe<UpdateTrainerPayload>;
  /** Updates a TrainerCourse. */
  updateTrainerCourse?: Maybe<UpdateTrainerCoursePayload>;
  /** Updates a TrainerPostCourseFeedback. */
  updateTrainerPostCourseFeedback?: Maybe<UpdateTrainerPostCourseFeedbackPayload>;
  /** Updates a TrainingClass. */
  updateTrainingClass?: Maybe<UpdateTrainingClassPayload>;
  /** Updates a TrainingSeat. */
  updateTrainingSeat?: Maybe<UpdateTrainingSeatPayload>;
  /** Updates a TrainingSeatReservation. */
  updateTrainingSeatReservation?: Maybe<UpdateTrainingSeatReservationPayload>;
  /** Updates a User. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a Workflow. */
  updateWorkflow?: Maybe<UpdateWorkflowPayload>;
  /** Updates a WorkflowAction. */
  updateWorkflowAction?: Maybe<UpdateWorkflowActionPayload>;
  /** Updates a WorkflowErrorLog. */
  updateWorkflowErrorLog?: Maybe<UpdateWorkflowErrorLogPayload>;
  /** Uploads a Asset. */
  uploadAsset?: Maybe<UploadAssetPayload>;
  /** UploadAvatars a User. */
  uploadAvatarUser?: Maybe<UploadAvatarUserPayload>;
};


export type MutationAcknowledgePurchaseOrderArgs = {
  input: AcknowledgePurchaseOrderInput;
};


export type MutationAssignAttendeesTrainingClassArgs = {
  input: AssignAttendeesTrainingClassInput;
};


export type MutationAssignCustomerContactBookingArgs = {
  input: AssignCustomerContactBookingInput;
};


export type MutationAssignTrainingSeatArgs = {
  input: AssignTrainingSeatInput;
};


export type MutationBuildEmailBuildContextArgs = {
  input: BuildEmailBuildContextInput;
};


export type MutationCancelBookingItemArgs = {
  input: CancelBookingItemInput;
};


export type MutationCancelChangeRequestArgs = {
  input: CancelChangeRequestInput;
};


export type MutationCancelPublicScheduleArgs = {
  input: CancelPublicScheduleInput;
};


export type MutationConfirmBookingArgs = {
  input: ConfirmBookingInput;
};


export type MutationConvertToTaxInvoiceInvoiceArgs = {
  input: ConvertToTaxInvoiceInvoiceInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateAttendeeArgs = {
  input: CreateAttendeeInput;
};


export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};


export type MutationCreateBookingItemArgs = {
  input: CreateBookingItemInput;
};


export type MutationCreateBookingNoteArgs = {
  input: CreateBookingNoteInput;
};


export type MutationCreateChangeRequestArgs = {
  input: CreateChangeRequestInput;
};


export type MutationCreateCourseVersionArgs = {
  input: CreateCourseVersionInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateCustomerContactArgs = {
  input: CreateCustomerContactInput;
};


export type MutationCreateCustomerLocationArgs = {
  input: CreateCustomerLocationInput;
};


export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};


export type MutationCreateEmailBuildContextArgs = {
  input: CreateEmailBuildContextInput;
};


export type MutationCreateEmailTemplateArgs = {
  input: CreateEmailTemplateInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateFileVersionArgs = {
  input: CreateFileVersionInput;
};


export type MutationCreateInternalContactArgs = {
  input: CreateInternalContactInput;
};


export type MutationCreateInvoiceEventLogArgs = {
  input: CreateInvoiceEventLogInput;
};


export type MutationCreateInvoiceItemArgs = {
  input: CreateInvoiceItemInput;
};


export type MutationCreateInvoicePaymentArgs = {
  input: CreateInvoicePaymentInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateNewPublicScheduleDatesArgs = {
  input: CreateNewPublicScheduleDatesInput;
};


export type MutationCreateNewSupplierArgs = {
  input: CreateNewSupplierInput;
};


export type MutationCreateNewTrainingClassArgs = {
  input: CreateNewTrainingClassInput;
};


export type MutationCreateNewUserArgs = {
  input: CreateNewUserInput;
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};


export type MutationCreateOrganisationTypeArgs = {
  input: CreateOrganisationTypeInput;
};


export type MutationCreateParameterArgs = {
  input: CreateParameterInput;
};


export type MutationCreatePersonArgs = {
  input: CreatePersonInput;
};


export type MutationCreatePublicCourseBookingArgs = {
  input: CreatePublicCourseBookingInput;
};


export type MutationCreatePurchaseOrderEventArgs = {
  input: CreatePurchaseOrderEventInput;
};


export type MutationCreatePurchaseOrderItemArgs = {
  input: CreatePurchaseOrderItemInput;
};


export type MutationCreatePurchaseOrderPublicScheduleArgs = {
  input: CreatePurchaseOrderPublicScheduleInput;
};


export type MutationCreateSubjectArgs = {
  input: CreateSubjectInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateSupplierContactArgs = {
  input: CreateSupplierContactInput;
};


export type MutationCreateSupplierLocationArgs = {
  input: CreateSupplierLocationInput;
};


export type MutationCreateSupplierNoteArgs = {
  input: CreateSupplierNoteInput;
};


export type MutationCreateTicketArgs = {
  input: CreateTicketInput;
};


export type MutationCreateTicketActivityArgs = {
  input: CreateTicketActivityInput;
};


export type MutationCreateTrainerArgs = {
  input: CreateTrainerInput;
};


export type MutationCreateTrainerCourseArgs = {
  input: CreateTrainerCourseInput;
};


export type MutationCreateTrainingClassNoteArgs = {
  input: CreateTrainingClassNoteInput;
};


export type MutationCreateTrainingSeatReservationArgs = {
  input: CreateTrainingSeatReservationInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};


export type MutationCreateWorkflowActionArgs = {
  input: CreateWorkflowActionInput;
};


export type MutationCreateWorkflowErrorLogArgs = {
  input: CreateWorkflowErrorLogInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteBookingArgs = {
  input: DeleteBookingInput;
};


export type MutationDeleteBookingCustomerContactArgs = {
  input: DeleteBookingCustomerContactInput;
};


export type MutationDeleteBookingItemArgs = {
  input: DeleteBookingItemInput;
};


export type MutationDeleteCourseArgs = {
  input: DeleteCourseInput;
};


export type MutationDeleteCourseVersionArgs = {
  input: DeleteCourseVersionInput;
};


export type MutationDeleteCustomerLocationArgs = {
  input: DeleteCustomerLocationInput;
};


export type MutationDeleteFileArgs = {
  input: DeleteFileInput;
};


export type MutationDeleteFileVersionArgs = {
  input: DeleteFileVersionInput;
};


export type MutationDeleteInternalContactArgs = {
  input: DeleteInternalContactInput;
};


export type MutationDeleteInvoiceEventLogArgs = {
  input: DeleteInvoiceEventLogInput;
};


export type MutationDeleteInvoiceItemArgs = {
  input: DeleteInvoiceItemInput;
};


export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
};


export type MutationDeleteNewPublicScheduleDatesArgs = {
  input: DeleteNewPublicScheduleDatesInput;
};


export type MutationDeleteNewTrainingClassArgs = {
  input: DeleteNewTrainingClassInput;
};


export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};


export type MutationDeleteOrganisationTypeArgs = {
  input: DeleteOrganisationTypeInput;
};


export type MutationDeleteParameterArgs = {
  input: DeleteParameterInput;
};


export type MutationDeletePersonArgs = {
  input: DeletePersonInput;
};


export type MutationDeletePurchaseOrderEventArgs = {
  input: DeletePurchaseOrderEventInput;
};


export type MutationDeletePurchaseOrderItemArgs = {
  input: DeletePurchaseOrderItemInput;
};


export type MutationDeleteSupplierLocationArgs = {
  input: DeleteSupplierLocationInput;
};


export type MutationDeleteTrainerCourseArgs = {
  input: DeleteTrainerCourseInput;
};


export type MutationDeleteTrainingSeatReservationArgs = {
  input: DeleteTrainingSeatReservationInput;
};


export type MutationDeleteWorkflowArgs = {
  input: DeleteWorkflowInput;
};


export type MutationDeleteWorkflowActionArgs = {
  input: DeleteWorkflowActionInput;
};


export type MutationDeleteWorkflowErrorLogArgs = {
  input: DeleteWorkflowErrorLogInput;
};


export type MutationImplementChangeRequestArgs = {
  input: ImplementChangeRequestInput;
};


export type MutationRejectChangeRequestArgs = {
  input: RejectChangeRequestInput;
};


export type MutationResendEmailArgs = {
  input: ResendEmailInput;
};


export type MutationSendEmailBuildContextArgs = {
  input: SendEmailBuildContextInput;
};


export type MutationSubstituteTrainerBookingItemArgs = {
  input: SubstituteTrainerBookingItemInput;
};


export type MutationSubstituteTrainerPublicScheduleArgs = {
  input: SubstituteTrainerPublicScheduleInput;
};


export type MutationUnassignTrainingSeatArgs = {
  input: UnassignTrainingSeatInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAllEmailArgs = {
  input: UpdateAllEmailInput;
};


export type MutationUpdateBillingAddressAndPoNumberInvoiceArgs = {
  input: UpdateBillingAddressAndPoNumberInvoiceInput;
};


export type MutationUpdateBookingArgs = {
  input: UpdateBookingInput;
};


export type MutationUpdateBookingItemArgs = {
  input: UpdateBookingItemInput;
};


export type MutationUpdateChangeRequestActionArgs = {
  input: UpdateChangeRequestActionInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateCourseVersionArgs = {
  input: UpdateCourseVersionInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerContactArgs = {
  input: UpdateCustomerContactInput;
};


export type MutationUpdateCustomerLocationArgs = {
  input: UpdateCustomerLocationInput;
};


export type MutationUpdateDocumentDateAndNotesInvoiceArgs = {
  input: UpdateDocumentDateAndNotesInvoiceInput;
};


export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};


export type MutationUpdateEmailTemplateArgs = {
  input: UpdateEmailTemplateInput;
};


export type MutationUpdateFileArgs = {
  input: UpdateFileInput;
};


export type MutationUpdateFileVersionArgs = {
  input: UpdateFileVersionInput;
};


export type MutationUpdateInternalContactArgs = {
  input: UpdateInternalContactInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceEventLogArgs = {
  input: UpdateInvoiceEventLogInput;
};


export type MutationUpdateInvoiceItemArgs = {
  input: UpdateInvoiceItemInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateMeetingLinkTrainingSessionArgs = {
  input: UpdateMeetingLinkTrainingSessionInput;
};


export type MutationUpdateNewPublicScheduleDatesArgs = {
  input: UpdateNewPublicScheduleDatesInput;
};


export type MutationUpdateNewTrainingClassArgs = {
  input: UpdateNewTrainingClassInput;
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};


export type MutationUpdateOrganisationTypeArgs = {
  input: UpdateOrganisationTypeInput;
};


export type MutationUpdateParameterArgs = {
  input: UpdateParameterInput;
};


export type MutationUpdatePersonArgs = {
  input: UpdatePersonInput;
};


export type MutationUpdatePoNumberBookingItemArgs = {
  input: UpdatePoNumberBookingItemInput;
};


export type MutationUpdatePostCourseFeedbackArgs = {
  input: UpdatePostCourseFeedbackInput;
};


export type MutationUpdatePreCourseEnrollmentArgs = {
  input: UpdatePreCourseEnrollmentInput;
};


export type MutationUpdatePurchaseOrderEventArgs = {
  input: UpdatePurchaseOrderEventInput;
};


export type MutationUpdatePurchaseOrderItemArgs = {
  input: UpdatePurchaseOrderItemInput;
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


export type MutationUpdateSupplierContactArgs = {
  input: UpdateSupplierContactInput;
};


export type MutationUpdateSupplierLocationArgs = {
  input: UpdateSupplierLocationInput;
};


export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput;
};


export type MutationUpdateTrainerArgs = {
  input: UpdateTrainerInput;
};


export type MutationUpdateTrainerCourseArgs = {
  input: UpdateTrainerCourseInput;
};


export type MutationUpdateTrainerPostCourseFeedbackArgs = {
  input: UpdateTrainerPostCourseFeedbackInput;
};


export type MutationUpdateTrainingClassArgs = {
  input: UpdateTrainingClassInput;
};


export type MutationUpdateTrainingSeatArgs = {
  input: UpdateTrainingSeatInput;
};


export type MutationUpdateTrainingSeatReservationArgs = {
  input: UpdateTrainingSeatReservationInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowInput;
};


export type MutationUpdateWorkflowActionArgs = {
  input: UpdateWorkflowActionInput;
};


export type MutationUpdateWorkflowErrorLogArgs = {
  input: UpdateWorkflowErrorLogInput;
};


export type MutationUploadAssetArgs = {
  input: UploadAssetInput;
};


export type MutationUploadAvatarUserArgs = {
  input: UploadAvatarUserInput;
};

export type NewPublicScheduleDates = Node & {
  __typename?: 'NewPublicScheduleDates';
  id: Scalars['ID'];
};

/** Cursor connection for NewPublicScheduleDates. */
export type NewPublicScheduleDatesCursorConnection = {
  __typename?: 'NewPublicScheduleDatesCursorConnection';
  edges?: Maybe<Array<Maybe<NewPublicScheduleDatesEdge>>>;
  pageInfo: NewPublicScheduleDatesPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of NewPublicScheduleDates. */
export type NewPublicScheduleDatesEdge = {
  __typename?: 'NewPublicScheduleDatesEdge';
  cursor: Scalars['String'];
  node?: Maybe<NewPublicScheduleDates>;
};

/** Information about the current page. */
export type NewPublicScheduleDatesPageInfo = {
  __typename?: 'NewPublicScheduleDatesPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type NewSupplier = Node & {
  __typename?: 'NewSupplier';
  _id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  purchasesLocation: Address;
};

export type NewTrainingClass = Node & {
  __typename?: 'NewTrainingClass';
  _id: Scalars['String'];
  id: Scalars['ID'];
  trainingClass?: Maybe<TrainingClass>;
};

/** Cursor connection for NewTrainingClass. */
export type NewTrainingClassCursorConnection = {
  __typename?: 'NewTrainingClassCursorConnection';
  edges?: Maybe<Array<Maybe<NewTrainingClassEdge>>>;
  pageInfo: NewTrainingClassPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of NewTrainingClass. */
export type NewTrainingClassEdge = {
  __typename?: 'NewTrainingClassEdge';
  cursor: Scalars['String'];
  node?: Maybe<NewTrainingClass>;
};

/** Information about the current page. */
export type NewTrainingClassPageInfo = {
  __typename?: 'NewTrainingClassPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type NewUser = Node & {
  __typename?: 'NewUser';
  _id: Scalars['String'];
  id: Scalars['ID'];
  user?: Maybe<User>;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID'];
};

export type Organisation = Node & {
  __typename?: 'Organisation';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  name: Scalars['String'];
  reference: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  type: OrganisationType;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  vatNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};

/** Cursor connection for Organisation. */
export type OrganisationCursorConnection = {
  __typename?: 'OrganisationCursorConnection';
  edges?: Maybe<Array<Maybe<OrganisationEdge>>>;
  pageInfo: OrganisationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Organisation. */
export type OrganisationEdge = {
  __typename?: 'OrganisationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Organisation>;
};

/** Information about the current page. */
export type OrganisationPageInfo = {
  __typename?: 'OrganisationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type OrganisationType = Node & {
  __typename?: 'OrganisationType';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Cursor connection for OrganisationType. */
export type OrganisationTypeCursorConnection = {
  __typename?: 'OrganisationTypeCursorConnection';
  edges?: Maybe<Array<Maybe<OrganisationTypeEdge>>>;
  pageInfo: OrganisationTypePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of OrganisationType. */
export type OrganisationTypeEdge = {
  __typename?: 'OrganisationTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<OrganisationType>;
};

export type OrganisationTypeFilter_Order = {
  name?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type OrganisationTypePageInfo = {
  __typename?: 'OrganisationTypePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Parameter = Node & {
  __typename?: 'Parameter';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  path: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** Cursor connection for Parameter. */
export type ParameterCursorConnection = {
  __typename?: 'ParameterCursorConnection';
  edges?: Maybe<Array<Maybe<ParameterEdge>>>;
  pageInfo: ParameterPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Parameter. */
export type ParameterEdge = {
  __typename?: 'ParameterEdge';
  cursor: Scalars['String'];
  node?: Maybe<Parameter>;
};

/** Information about the current page. */
export type ParameterPageInfo = {
  __typename?: 'ParameterPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** PaymentMethod Enum */
export enum PaymentMethod {
  BankTransfer = 'bank_transfer',
  CreditCard = 'credit_card'
}

export type Person = Node & {
  __typename?: 'Person';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

/** Cursor connection for Person. */
export type PersonCursorConnection = {
  __typename?: 'PersonCursorConnection';
  edges?: Maybe<Array<Maybe<PersonEdge>>>;
  pageInfo: PersonPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Person. */
export type PersonEdge = {
  __typename?: 'PersonEdge';
  cursor: Scalars['String'];
  node?: Maybe<Person>;
};

export type PersonInputType = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
};

/** Information about the current page. */
export type PersonPageInfo = {
  __typename?: 'PersonPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PostCourseFeedback = Node & {
  __typename?: 'PostCourseFeedback';
  brand: Brand;
  courseTitle: Scalars['String'];
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  id: Scalars['ID'];
  pdfUrl: Scalars['String'];
  postCourseQuestionaireAnswers?: Maybe<Scalars['Iterable']>;
  postCourseQuestionaireConfig?: Maybe<Scalars['Iterable']>;
  receiveNewsAndUpdates: Scalars['Boolean'];
  trainer: Scalars['Iterable'];
  trainingClass: TrainingClass;
  trainingSessions: Scalars['Iterable'];
  useInMarketing: Scalars['Boolean'];
};

export type PreCourseEnrollment = Node & {
  __typename?: 'PreCourseEnrollment';
  additionalNotes?: Maybe<Scalars['String']>;
  brand: Brand;
  contact: CustomerContact;
  courseAssets: Scalars['Iterable'];
  courseOutline?: Maybe<Scalars['Iterable']>;
  courseTitle: Scalars['String'];
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  editable: Scalars['Boolean'];
  familyName: Scalars['String'];
  givenName: Scalars['String'];
  id: Scalars['ID'];
  preCourseQuestionaireAnswers?: Maybe<Scalars['Iterable']>;
  preCourseQuestionaireConfig?: Maybe<Scalars['Iterable']>;
  reviewedOutline?: Maybe<Scalars['Iterable']>;
  tailored: Scalars['Boolean'];
  trainer: Scalars['Iterable'];
  trainingSessions: Scalars['Iterable'];
};

export type PublicSchedule = Node & {
  __typename?: 'PublicSchedule';
  _id: Scalars['String'];
  class?: Maybe<TrainingClass>;
  coursePrice: Money;
  courseVersion?: Maybe<CourseVersion>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  currentPrice: Money;
  deliveryFormat?: Maybe<DeliveryFormat>;
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  id: Scalars['ID'];
  maxAttendees: Scalars['Int'];
  sessions: Scalars['Iterable'];
  showOnWebsite: Scalars['Boolean'];
  status: PublicScheduleStatus;
  trainer?: Maybe<Trainer>;
  trainerRate: Money;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for PublicSchedule. */
export type PublicScheduleCursorConnection = {
  __typename?: 'PublicScheduleCursorConnection';
  edges?: Maybe<Array<Maybe<PublicScheduleEdge>>>;
  pageInfo: PublicSchedulePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of PublicSchedule. */
export type PublicScheduleEdge = {
  __typename?: 'PublicScheduleEdge';
  cursor: Scalars['String'];
  node?: Maybe<PublicSchedule>;
};

export type PublicScheduleFilter_Order = {
  class_sessions_startsAt?: InputMaybe<Scalars['String']>;
  class_title?: InputMaybe<Scalars['String']>;
  deliveryFormat?: InputMaybe<Scalars['String']>;
  deliveryPlatform?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  trainer_contact_fullName?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type PublicSchedulePageInfo = {
  __typename?: 'PublicSchedulePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** PublicScheduleStatus Enum */
export enum PublicScheduleStatus {
  Canceled = 'canceled',
  Complete = 'complete',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Draft = 'draft'
}

export type PurchaseOrder = Node & {
  __typename?: 'PurchaseOrder';
  _id?: Maybe<Scalars['String']>;
  acknowledgedAt?: Maybe<Scalars['String']>;
  acknowledgedBy?: Maybe<User>;
  billingAddress: Address;
  brand?: Maybe<Brand>;
  contact?: Maybe<SupplierContact>;
  createdAt: Scalars['String'];
  deliveryAddress: Address;
  deliveryDate: Scalars['String'];
  events?: Maybe<Array<Maybe<PurchaseOrderEvent>>>;
  grossPrice: Money;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items?: Maybe<PurchaseOrderItemCursorConnection>;
  legacyId?: Maybe<Scalars['String']>;
  netPrice: Money;
  pdfUrl: Scalars['String'];
  raisedAt: Scalars['String'];
  reference: Scalars['String'];
  status: PurchaseOrderStatus;
  supplier?: Maybe<Supplier>;
  taxValue: Money;
  title?: Maybe<Scalars['String']>;
  trainingClass?: Maybe<TrainingClass>;
  updatedAt: Scalars['String'];
  xeroEditUrl?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
  xeroTransferable: Scalars['Boolean'];
};


export type PurchaseOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Cursor connection for PurchaseOrder. */
export type PurchaseOrderCursorConnection = {
  __typename?: 'PurchaseOrderCursorConnection';
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  pageInfo: PurchaseOrderPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of PurchaseOrder. */
export type PurchaseOrderEdge = {
  __typename?: 'PurchaseOrderEdge';
  cursor: Scalars['String'];
  node?: Maybe<PurchaseOrder>;
};

export type PurchaseOrderEvent = Node & {
  __typename?: 'PurchaseOrderEvent';
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  metaData: Scalars['Iterable'];
  purchaseOrder?: Maybe<PurchaseOrder>;
  updatedAt: Scalars['String'];
};

export type PurchaseOrderFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  raisedAt?: InputMaybe<Scalars['String']>;
};

export type PurchaseOrderItem = Node & {
  __typename?: 'PurchaseOrderItem';
  account?: Maybe<Account>;
  bookingItem?: Maybe<BookingItem>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  grossPrice: Money;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  netPrice: Money;
  publicSchedule?: Maybe<PublicSchedule>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  quantity: Scalars['String'];
  taxRatePercent: Scalars['String'];
  taxValue: Money;
  taxable: Scalars['Boolean'];
  unitPrice: Money;
  updatedAt: Scalars['String'];
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
};

/** Cursor connection for PurchaseOrderItem. */
export type PurchaseOrderItemCursorConnection = {
  __typename?: 'PurchaseOrderItemCursorConnection';
  edges?: Maybe<Array<Maybe<PurchaseOrderItemEdge>>>;
  pageInfo: PurchaseOrderItemPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of PurchaseOrderItem. */
export type PurchaseOrderItemEdge = {
  __typename?: 'PurchaseOrderItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<PurchaseOrderItem>;
};

/** Information about the current page. */
export type PurchaseOrderItemPageInfo = {
  __typename?: 'PurchaseOrderItemPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type PurchaseOrderPageInfo = {
  __typename?: 'PurchaseOrderPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** PurchaseOrderStatus Enum */
export enum PurchaseOrderStatus {
  Billed = 'billed',
  Canceled = 'canceled',
  Complete = 'complete',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Draft = 'draft',
  Provisional = 'provisional'
}

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountManagers?: Maybe<Array<Maybe<AccountManager>>>;
  accounts?: Maybe<AccountCursorConnection>;
  assets?: Maybe<Array<Maybe<Asset>>>;
  attendee?: Maybe<Attendee>;
  attendees?: Maybe<AttendeeCursorConnection>;
  availableBookingCustomerContacts?: Maybe<Array<Maybe<CustomerContact>>>;
  booking?: Maybe<Booking>;
  bookingEventLog?: Maybe<BookingEventLog>;
  bookingEventLogs?: Maybe<Array<Maybe<BookingEventLog>>>;
  bookingItem?: Maybe<BookingItem>;
  bookingItems?: Maybe<BookingItemCursorConnection>;
  bookings?: Maybe<BookingCursorConnection>;
  brand?: Maybe<Brand>;
  brands?: Maybe<BrandCursorConnection>;
  changeRequest?: Maybe<ChangeRequest>;
  changeRequests?: Maybe<ChangeRequestCursorConnection>;
  contactsBookings?: Maybe<Array<Maybe<Booking>>>;
  course?: Maybe<Course>;
  courseVersion?: Maybe<CourseVersion>;
  courseVersions?: Maybe<CourseVersionCursorConnection>;
  courses?: Maybe<CourseCursorConnection>;
  currentUser?: Maybe<User>;
  customer?: Maybe<Customer>;
  customerContact?: Maybe<CustomerContact>;
  customerContacts?: Maybe<CustomerContactCursorConnection>;
  customerLocation?: Maybe<CustomerLocation>;
  customerLocations?: Maybe<CustomerLocationCursorConnection>;
  customers?: Maybe<CustomerCursorConnection>;
  debtorTotalGrosses?: Maybe<Array<Maybe<DebtorTotalGross>>>;
  debtors?: Maybe<Array<Maybe<Debtor>>>;
  defaultBrand?: Maybe<Brand>;
  email?: Maybe<Email>;
  emailBuildContext?: Maybe<EmailBuildContext>;
  emailBuildContexts?: Maybe<EmailBuildContextCursorConnection>;
  emailLog?: Maybe<EmailLog>;
  emailLogs?: Maybe<EmailLogCursorConnection>;
  emailSampleData?: Maybe<EmailSampleData>;
  emailTemplate?: Maybe<EmailTemplate>;
  emailTemplatePreview?: Maybe<EmailTemplatePreview>;
  emailTemplates?: Maybe<EmailTemplateCursorConnection>;
  file?: Maybe<File>;
  fileVersion?: Maybe<FileVersion>;
  fileVersions?: Maybe<Array<Maybe<FileVersion>>>;
  files?: Maybe<FileCursorConnection>;
  internalContact?: Maybe<InternalContact>;
  internalContacts?: Maybe<InternalContactCursorConnection>;
  invoice?: Maybe<Invoice>;
  invoiceActivityLogs?: Maybe<InvoiceActivityLogCursorConnection>;
  invoiceEventLog?: Maybe<InvoiceEventLog>;
  invoiceEventLogs?: Maybe<Array<Maybe<InvoiceEventLog>>>;
  invoiceItem?: Maybe<InvoiceItem>;
  invoiceItems?: Maybe<InvoiceItemCursorConnection>;
  invoicePayment?: Maybe<InvoicePayment>;
  invoicePayments?: Maybe<InvoicePaymentCursorConnection>;
  invoices?: Maybe<InvoiceCursorConnection>;
  location?: Maybe<Location>;
  locations?: Maybe<LocationCursorConnection>;
  myCompletedAssignedTrainingClasses?: Maybe<Array<Maybe<AssignedTrainingClass>>>;
  myTrainingCoursesTrainingClasses?: Maybe<TrainingClassCursorConnection>;
  myUpcomingAssignedTrainingClasses?: Maybe<Array<Maybe<AssignedTrainingClass>>>;
  newPublicScheduleDates?: Maybe<NewPublicScheduleDatesCursorConnection>;
  newTrainingClass?: Maybe<NewTrainingClass>;
  newTrainingClasses?: Maybe<NewTrainingClassCursorConnection>;
  node?: Maybe<Node>;
  organisation?: Maybe<Organisation>;
  organisationType?: Maybe<OrganisationType>;
  organisationTypes?: Maybe<OrganisationTypeCursorConnection>;
  organisations?: Maybe<OrganisationCursorConnection>;
  parameter?: Maybe<Parameter>;
  parameters?: Maybe<ParameterCursorConnection>;
  people?: Maybe<PersonCursorConnection>;
  person?: Maybe<Person>;
  postCourseFeedback?: Maybe<PostCourseFeedback>;
  preCourseEnrollment?: Maybe<PreCourseEnrollment>;
  publicSchedule?: Maybe<PublicSchedule>;
  publicSchedules?: Maybe<PublicScheduleCursorConnection>;
  purchaseOrder?: Maybe<PurchaseOrder>;
  purchaseOrderEvent?: Maybe<PurchaseOrderEvent>;
  purchaseOrderEvents?: Maybe<Array<Maybe<PurchaseOrderEvent>>>;
  purchaseOrderItem?: Maybe<PurchaseOrderItem>;
  purchaseOrderItems?: Maybe<PurchaseOrderItemCursorConnection>;
  purchaseOrders?: Maybe<PurchaseOrderCursorConnection>;
  questionaire?: Maybe<Questionaire>;
  questionaireAnswer?: Maybe<QuestionaireAnswer>;
  questionaires?: Maybe<QuestionaireCursorConnection>;
  referenceSearches?: Maybe<Array<Maybe<ReferenceSearch>>>;
  subject?: Maybe<Subject>;
  subjects?: Maybe<SubjectCursorConnection>;
  supplier?: Maybe<Supplier>;
  supplierContact?: Maybe<SupplierContact>;
  supplierLocation?: Maybe<SupplierLocation>;
  supplierLocations?: Maybe<SupplierLocationCursorConnection>;
  suppliers?: Maybe<SupplierCursorConnection>;
  ticket?: Maybe<Ticket>;
  tickets?: Maybe<TicketCursorConnection>;
  trainer?: Maybe<Trainer>;
  trainerCourses?: Maybe<TrainerCourseCursorConnection>;
  trainerPostCourseFeedback?: Maybe<TrainerPostCourseFeedback>;
  trainers?: Maybe<TrainerCursorConnection>;
  trainingClass?: Maybe<TrainingClass>;
  trainingClassEventLog?: Maybe<TrainingClassEventLog>;
  trainingClassEventLogs?: Maybe<Array<Maybe<TrainingClassEventLog>>>;
  trainingClasses?: Maybe<TrainingClassCursorConnection>;
  trainingSeatReservation?: Maybe<TrainingSeatReservation>;
  trainingSeatReservations?: Maybe<TrainingSeatReservationCursorConnection>;
  trainingSeats?: Maybe<TrainingSeatCursorConnection>;
  trainingSessions?: Maybe<TrainingSessionCursorConnection>;
  unassignedToTrainerCourses?: Maybe<Array<Maybe<Course>>>;
  unlayerMergeTags?: Maybe<UnlayerMergeTags>;
  user?: Maybe<User>;
  users?: Maybe<UserCursorConnection>;
  workflow?: Maybe<Workflow>;
  workflowAction?: Maybe<WorkflowAction>;
  workflowActions?: Maybe<WorkflowActionCursorConnection>;
  workflowErrorLog?: Maybe<WorkflowErrorLog>;
  workflowErrorLogs?: Maybe<Array<Maybe<WorkflowErrorLog>>>;
  workflowResources?: Maybe<Array<Maybe<WorkflowResource>>>;
  workflows?: Maybe<WorkflowCursorConnection>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryAssetsArgs = {
  course?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<AssetFilter_Order>>>;
};


export type QueryAttendeeArgs = {
  id: Scalars['ID'];
};


export type QueryAttendeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerContact?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<AttendeeFilter_Order>>>;
};


export type QueryAvailableBookingCustomerContactsArgs = {
  booking: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingEventLogArgs = {
  id: Scalars['ID'];
};


export type QueryBookingItemArgs = {
  id: Scalars['ID'];
};


export type QueryBookingItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<BookingItemFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingItemFilter_Order>>>;
};


export type QueryBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Array<InputMaybe<BookingFilter_CreatedAt>>>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingFilter_Order>>>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BrandFilter_Order>>>;
};


export type QueryChangeRequestArgs = {
  id: Scalars['ID'];
};


export type QueryChangeRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Array<InputMaybe<ChangeRequestFilter_CreatedAt>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<ChangeRequestFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContactsBookingsArgs = {
  customerContact: Scalars['ID'];
};


export type QueryCourseArgs = {
  id: Scalars['ID'];
};


export type QueryCourseVersionArgs = {
  id: Scalars['ID'];
};


export type QueryCourseVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<CourseVersionFilter_Order>>>;
};


export type QueryCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<CourseFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  latestCourseVersion_subject_title?: InputMaybe<Scalars['String']>;
  latestCourseVersion_title?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CourseFilter_Order>>>;
  publishedCourseVersion_title?: InputMaybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerContactArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  booking?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Scalars['Iterable']>;
};


export type QueryCustomerLocationArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<CustomerFilter_Order>>>;
};


export type QueryEmailArgs = {
  id: Scalars['ID'];
};


export type QueryEmailBuildContextArgs = {
  id: Scalars['ID'];
};


export type QueryEmailBuildContextsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  emailTemplate_brand?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EmailBuildContextFilter_Order>>>;
  targetResource?: InputMaybe<Scalars['String']>;
  targetResourceId?: InputMaybe<Scalars['String']>;
  targetResource_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEmailLogArgs = {
  id: Scalars['ID'];
};


export type QueryEmailLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EmailLogFilter_Order>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryEmailSampleDataArgs = {
  contactTypes: Array<InputMaybe<Scalars['String']>>;
  resourceName: Scalars['String'];
  resourceReference?: InputMaybe<Scalars['String']>;
};


export type QueryEmailTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryEmailTemplatePreviewArgs = {
  data: Scalars['String'];
  template: Scalars['String'];
};


export type QueryEmailTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<EmailTemplateFilter_Order>>>;
  targetResource?: InputMaybe<Scalars['String']>;
  targetResource_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFileVersionArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryInternalContactArgs = {
  id: Scalars['ID'];
};


export type QueryInternalContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceActivityLogsArgs = {
  action?: InputMaybe<Scalars['String']>;
  action_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InvoiceActivityLogFilter_Order>>>;
  user_roles?: InputMaybe<Scalars['Iterable']>;
};


export type QueryInvoiceEventLogArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceItemArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoicePaymentArgs = {
  id: Scalars['ID'];
};


export type QueryInvoicePaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<InvoiceFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMyTrainingCoursesTrainingClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingClassFilter_Order>>>;
  sessions_seats_trainingSeatReservation_attendee_customerContact?: InputMaybe<Scalars['String']>;
};


export type QueryNewPublicScheduleDatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryNewTrainingClassArgs = {
  id: Scalars['ID'];
};


export type QueryNewTrainingClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<OrganisationTypeFilter_Order>>>;
};


export type QueryOrganisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryParameterArgs = {
  id: Scalars['ID'];
};


export type QueryParametersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPeopleArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPersonArgs = {
  id: Scalars['ID'];
};


export type QueryPostCourseFeedbackArgs = {
  hash: Scalars['String'];
};


export type QueryPreCourseEnrollmentArgs = {
  hash: Scalars['String'];
};


export type QueryPublicScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryPublicSchedulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  class_title?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<PublicScheduleFilter_Order>>>;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderEventArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderItemArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseOrderItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<PurchaseOrderFilter_Order>>>;
  reference?: InputMaybe<Scalars['String']>;
};


export type QueryQuestionaireArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionaireAnswerArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionairesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryReferenceSearchesArgs = {
  reference: Scalars['String'];
};


export type QuerySubjectArgs = {
  id: Scalars['ID'];
};


export type QuerySubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<SubjectFilter_Order>>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierContactArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierLocationArgs = {
  id: Scalars['ID'];
};


export type QuerySupplierLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['String']>;
};


export type QuerySuppliersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<InputMaybe<SupplierFilter_Order>>>;
};


export type QueryTicketArgs = {
  id: Scalars['ID'];
};


export type QueryTicketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  booking?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TicketFilter_Order>>>;
  status?: InputMaybe<Scalars['String']>;
  status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTrainerArgs = {
  id: Scalars['ID'];
};


export type QueryTrainerCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  course_publishedCourseVersion_title?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Order>>>;
  trainer?: InputMaybe<Scalars['String']>;
};


export type QueryTrainerPostCourseFeedbackArgs = {
  hash: Scalars['String'];
};


export type QueryTrainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contact_familyName?: InputMaybe<Scalars['String']>;
  contact_fullName?: InputMaybe<Scalars['String']>;
  contact_givenName?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainerFilter_Order>>>;
};


export type QueryTrainingClassArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingClassEventLogArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingClassFilter_Order>>>;
  sessions_seats_trainingSeatReservation_attendee_customerContact?: InputMaybe<Scalars['String']>;
};


export type QueryTrainingSeatReservationArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingSeatReservationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingSeatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  trainingSession?: InputMaybe<Scalars['String']>;
};


export type QueryTrainingSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  class_brand?: InputMaybe<Scalars['String']>;
  class_public?: InputMaybe<Scalars['Boolean']>;
  class_status?: InputMaybe<Scalars['String']>;
  class_status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  class_trainer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingSessionFilter_Order>>>;
  startsAt?: InputMaybe<Array<InputMaybe<TrainingSessionFilter_StartsAt>>>;
};


export type QueryUnassignedToTrainerCoursesArgs = {
  trainerId: Scalars['ID'];
};


export type QueryUnlayerMergeTagsArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<UserFilter_Order>>>;
  roles?: InputMaybe<Scalars['Iterable']>;
};


export type QueryWorkflowArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowActionArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryWorkflowErrorLogArgs = {
  id: Scalars['ID'];
};


export type QueryWorkflowsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<WorkflowFilter_Order>>>;
};

export type Questionaire = Node & {
  __typename?: 'Questionaire';
  answers?: Maybe<Array<Maybe<QuestionaireAnswer>>>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  postCourseSubjects?: Maybe<SubjectCursorConnection>;
  questions: Scalars['Iterable'];
  subjects?: Maybe<SubjectCursorConnection>;
  updatedAt: Scalars['String'];
};


export type QuestionairePostCourseSubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<SubjectFilter_Order>>>;
};


export type QuestionaireSubjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<SubjectFilter_Order>>>;
};

export type QuestionaireAnswer = Node & {
  __typename?: 'QuestionaireAnswer';
  answers?: Maybe<Scalars['Iterable']>;
  attendee?: Maybe<Attendee>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  questionaire?: Maybe<Questionaire>;
  updatedAt: Scalars['String'];
};

/** Cursor connection for Questionaire. */
export type QuestionaireCursorConnection = {
  __typename?: 'QuestionaireCursorConnection';
  edges?: Maybe<Array<Maybe<QuestionaireEdge>>>;
  pageInfo: QuestionairePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Questionaire. */
export type QuestionaireEdge = {
  __typename?: 'QuestionaireEdge';
  cursor: Scalars['String'];
  node?: Maybe<Questionaire>;
};

/** Information about the current page. */
export type QuestionairePageInfo = {
  __typename?: 'QuestionairePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type ReferenceSearch = Node & {
  __typename?: 'ReferenceSearch';
  _id: Scalars['String'];
  entity: Scalars['String'];
  id: Scalars['ID'];
  reference: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Subject = Node & {
  __typename?: 'Subject';
  _id: Scalars['String'];
  courses?: Maybe<CourseVersionCursorConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  postCourseQuestionaire?: Maybe<Questionaire>;
  preCourseQuestionaire?: Maybe<Questionaire>;
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type SubjectCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<CourseVersionFilter_Order>>>;
};

/** Cursor connection for Subject. */
export type SubjectCursorConnection = {
  __typename?: 'SubjectCursorConnection';
  edges?: Maybe<Array<Maybe<SubjectEdge>>>;
  pageInfo: SubjectPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Subject. */
export type SubjectEdge = {
  __typename?: 'SubjectEdge';
  cursor: Scalars['String'];
  node?: Maybe<Subject>;
};

export type SubjectFilter_Order = {
  title?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type SubjectPageInfo = {
  __typename?: 'SubjectPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Supplier = Node & {
  __typename?: 'Supplier';
  _id: Scalars['String'];
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankSortCode?: Maybe<Scalars['String']>;
  contacts?: Maybe<SupplierContactCursorConnection>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  locations?: Maybe<SupplierLocationCursorConnection>;
  name: Scalars['String'];
  notes?: Maybe<SupplierNoteCursorConnection>;
  primaryContact?: Maybe<SupplierContact>;
  purchasesEmail?: Maybe<Scalars['String']>;
  purchasesLocation?: Maybe<SupplierLocation>;
  reference: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  remittanceEmail?: Maybe<Scalars['String']>;
  trainers?: Maybe<TrainerCursorConnection>;
  type: OrganisationType;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  vatNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  xeroId?: Maybe<Scalars['String']>;
  xeroLastSyncedAt?: Maybe<Scalars['String']>;
};


export type SupplierContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  fullName?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<SupplierContactFilter_Order>>>;
};


export type SupplierLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  supplier?: InputMaybe<Scalars['String']>;
};


export type SupplierNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<SupplierNoteFilter_Order>>>;
};


export type SupplierTrainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  contact_familyName?: InputMaybe<Scalars['String']>;
  contact_fullName?: InputMaybe<Scalars['String']>;
  contact_givenName?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainerFilter_Order>>>;
};

export type SupplierContact = Node & {
  __typename?: 'SupplierContact';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
  telephone?: Maybe<Scalars['String']>;
  trainer?: Maybe<Trainer>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

/** Cursor connection for SupplierContact. */
export type SupplierContactCursorConnection = {
  __typename?: 'SupplierContactCursorConnection';
  edges?: Maybe<Array<Maybe<SupplierContactEdge>>>;
  pageInfo: SupplierContactPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of SupplierContact. */
export type SupplierContactEdge = {
  __typename?: 'SupplierContactEdge';
  cursor: Scalars['String'];
  node?: Maybe<SupplierContact>;
};

export type SupplierContactFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type SupplierContactPageInfo = {
  __typename?: 'SupplierContactPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Supplier. */
export type SupplierCursorConnection = {
  __typename?: 'SupplierCursorConnection';
  edges?: Maybe<Array<Maybe<SupplierEdge>>>;
  pageInfo: SupplierPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Supplier. */
export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  cursor: Scalars['String'];
  node?: Maybe<Supplier>;
};

export type SupplierFilter_Order = {
  name?: InputMaybe<Scalars['String']>;
};

export type SupplierLocation = Node & {
  __typename?: 'SupplierLocation';
  _id: Scalars['String'];
  address: Address;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for SupplierLocation. */
export type SupplierLocationCursorConnection = {
  __typename?: 'SupplierLocationCursorConnection';
  edges?: Maybe<Array<Maybe<SupplierLocationEdge>>>;
  pageInfo: SupplierLocationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of SupplierLocation. */
export type SupplierLocationEdge = {
  __typename?: 'SupplierLocationEdge';
  cursor: Scalars['String'];
  node?: Maybe<SupplierLocation>;
};

/** Information about the current page. */
export type SupplierLocationPageInfo = {
  __typename?: 'SupplierLocationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type SupplierNote = Node & {
  __typename?: 'SupplierNote';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for SupplierNote. */
export type SupplierNoteCursorConnection = {
  __typename?: 'SupplierNoteCursorConnection';
  edges?: Maybe<Array<Maybe<SupplierNoteEdge>>>;
  pageInfo: SupplierNotePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of SupplierNote. */
export type SupplierNoteEdge = {
  __typename?: 'SupplierNoteEdge';
  cursor: Scalars['String'];
  node?: Maybe<SupplierNote>;
};

export type SupplierNoteFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type SupplierNotePageInfo = {
  __typename?: 'SupplierNotePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type SupplierPageInfo = {
  __typename?: 'SupplierPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** TargetResource Enum */
export enum TargetResource {
  Booking = 'booking',
  BookingItem = 'booking_item',
  Invoice = 'invoice',
  PurchaseOrder = 'purchase_order',
  TrainingClass = 'training_class'
}

export type Ticket = Node & {
  __typename?: 'Ticket';
  _id: Scalars['String'];
  activities?: Maybe<TicketActivityCursorConnection>;
  booking?: Maybe<Booking>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  reference: Scalars['String'];
  status: TicketStatus;
  subject: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type TicketActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TicketActivityFilter_Order>>>;
};

export type TicketActivity = Node & {
  __typename?: 'TicketActivity';
  _id: Scalars['String'];
  attributeChanges?: Maybe<Scalars['Iterable']>;
  body: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  ticket?: Maybe<Ticket>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for TicketActivity. */
export type TicketActivityCursorConnection = {
  __typename?: 'TicketActivityCursorConnection';
  edges?: Maybe<Array<Maybe<TicketActivityEdge>>>;
  pageInfo: TicketActivityPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TicketActivity. */
export type TicketActivityEdge = {
  __typename?: 'TicketActivityEdge';
  cursor: Scalars['String'];
  node?: Maybe<TicketActivity>;
};

export type TicketActivityFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TicketActivityPageInfo = {
  __typename?: 'TicketActivityPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Ticket. */
export type TicketCursorConnection = {
  __typename?: 'TicketCursorConnection';
  edges?: Maybe<Array<Maybe<TicketEdge>>>;
  pageInfo: TicketPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Ticket. */
export type TicketEdge = {
  __typename?: 'TicketEdge';
  cursor: Scalars['String'];
  node?: Maybe<Ticket>;
};

export type TicketFilter_Order = {
  booking_customer_name?: InputMaybe<Scalars['String']>;
  booking_reference?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy_person_fullName?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TicketPageInfo = {
  __typename?: 'TicketPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** TicketStatus Enum */
export enum TicketStatus {
  Closed = 'closed',
  InProgress = 'in_progress',
  Open = 'open'
}

export type Trainer = Node & {
  __typename?: 'Trainer';
  _id: Scalars['String'];
  calendarExportUrl: Scalars['String'];
  calendarUrl?: Maybe<Scalars['String']>;
  contact?: Maybe<SupplierContact>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  location?: Maybe<SupplierLocation>;
  notes?: Maybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  supplier?: Maybe<Supplier>;
  trainerCourses?: Maybe<TrainerCourseCursorConnection>;
  trainingClasses?: Maybe<TrainingClassCursorConnection>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type TrainerTrainerCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  course_publishedCourseVersion_title?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainerCourseFilter_Order>>>;
  trainer?: InputMaybe<Scalars['String']>;
};


export type TrainerTrainingClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingClassFilter_Order>>>;
  sessions_seats_trainingSeatReservation_attendee_customerContact?: InputMaybe<Scalars['String']>;
};

export type TrainerCourse = Node & {
  __typename?: 'TrainerCourse';
  _id: Scalars['String'];
  competency: Scalars['String'];
  competencySelf?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  rate: Money;
  trainer?: Maybe<Trainer>;
  updatedAt: Scalars['String'];
};

/** Cursor connection for TrainerCourse. */
export type TrainerCourseCursorConnection = {
  __typename?: 'TrainerCourseCursorConnection';
  edges?: Maybe<Array<Maybe<TrainerCourseEdge>>>;
  pageInfo: TrainerCoursePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainerCourse. */
export type TrainerCourseEdge = {
  __typename?: 'TrainerCourseEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainerCourse>;
};

export type TrainerCourseFilter_Exists = {
  course_publishedCourseVersion?: InputMaybe<Scalars['Boolean']>;
};

export type TrainerCourseFilter_Order = {
  competency?: InputMaybe<Scalars['String']>;
  competencySelf?: InputMaybe<Scalars['String']>;
  course_publishedCourseVersion_title?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['String']>;
  trainer_contact_fullName?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TrainerCoursePageInfo = {
  __typename?: 'TrainerCoursePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Cursor connection for Trainer. */
export type TrainerCursorConnection = {
  __typename?: 'TrainerCursorConnection';
  edges?: Maybe<Array<Maybe<TrainerEdge>>>;
  pageInfo: TrainerPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Trainer. */
export type TrainerEdge = {
  __typename?: 'TrainerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Trainer>;
};

export type TrainerFilter_Order = {
  contact_familyName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TrainerPageInfo = {
  __typename?: 'TrainerPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type TrainerPostCourseFeedback = Node & {
  __typename?: 'TrainerPostCourseFeedback';
  courseTitle: Scalars['String'];
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  id: Scalars['ID'];
  postCourseFeedbackAnswers?: Maybe<Scalars['Iterable']>;
  postCourseFeedbackConfig?: Maybe<Scalars['Iterable']>;
  trainer: Scalars['Iterable'];
  trainingClass: TrainingClass;
  trainingSessions: Scalars['Iterable'];
};

export type TrainingClass = Node & {
  __typename?: 'TrainingClass';
  _id: Scalars['String'];
  activePurchaseOrder: Scalars['Boolean'];
  additionalNotes?: Maybe<Scalars['String']>;
  bookingItem?: Maybe<BookingItem>;
  bookingItems?: Maybe<BookingItemCursorConnection>;
  brand: Brand;
  courseVersion?: Maybe<CourseVersion>;
  createdAt: Scalars['String'];
  customerName: Scalars['String'];
  deliveryAddress: Address;
  deliveryFormat?: Maybe<DeliveryFormat>;
  deliveryPlatform?: Maybe<DeliveryPlatform>;
  durationDays: Scalars['Float'];
  ended: Scalars['Boolean'];
  hash: Scalars['String'];
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  maxAttendees: Scalars['Int'];
  notes?: Maybe<TrainingClassNoteCursorConnection>;
  public: Scalars['Boolean'];
  publicSchedule?: Maybe<PublicSchedule>;
  purchaseOrderReference: Scalars['String'];
  purchaseOrders?: Maybe<PurchaseOrderCursorConnection>;
  reference: Scalars['String'];
  sessions?: Maybe<TrainingSessionCursorConnection>;
  started: Scalars['Boolean'];
  status?: Maybe<TrainingClassStatus>;
  tailored: Scalars['Boolean'];
  title: Scalars['String'];
  trainer?: Maybe<Trainer>;
  trainerPostCourseFeedbackAnswer?: Maybe<QuestionaireAnswer>;
  trainerPostCourseFeedbackUrl: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type TrainingClassBookingItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Array<InputMaybe<BookingItemFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<BookingItemFilter_Order>>>;
};


export type TrainingClassNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingClassNoteFilter_Order>>>;
};


export type TrainingClassPurchaseOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<PurchaseOrderFilter_Order>>>;
  reference?: InputMaybe<Scalars['String']>;
};


export type TrainingClassSessionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  class_brand?: InputMaybe<Scalars['String']>;
  class_public?: InputMaybe<Scalars['Boolean']>;
  class_status?: InputMaybe<Scalars['String']>;
  class_status_list?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  class_trainer?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<InputMaybe<TrainingSessionFilter_Order>>>;
  startsAt?: InputMaybe<Array<InputMaybe<TrainingSessionFilter_StartsAt>>>;
};

/** Cursor connection for TrainingClass. */
export type TrainingClassCursorConnection = {
  __typename?: 'TrainingClassCursorConnection';
  edges?: Maybe<Array<Maybe<TrainingClassEdge>>>;
  pageInfo: TrainingClassPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainingClass. */
export type TrainingClassEdge = {
  __typename?: 'TrainingClassEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainingClass>;
};

export type TrainingClassEventLog = Node & {
  __typename?: 'TrainingClassEventLog';
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  metaData: Scalars['Iterable'];
  trainingClass?: Maybe<TrainingClass>;
  trainingSession?: Maybe<TrainingSession>;
  updatedAt: Scalars['String'];
};

export type TrainingClassFilter_Order = {
  title?: InputMaybe<Scalars['String']>;
};

export type TrainingClassNote = Node & {
  __typename?: 'TrainingClassNote';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  trainingClass?: Maybe<TrainingClass>;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for TrainingClassNote. */
export type TrainingClassNoteCursorConnection = {
  __typename?: 'TrainingClassNoteCursorConnection';
  edges?: Maybe<Array<Maybe<TrainingClassNoteEdge>>>;
  pageInfo: TrainingClassNotePageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainingClassNote. */
export type TrainingClassNoteEdge = {
  __typename?: 'TrainingClassNoteEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainingClassNote>;
};

export type TrainingClassNoteFilter_Order = {
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TrainingClassNotePageInfo = {
  __typename?: 'TrainingClassNotePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** Information about the current page. */
export type TrainingClassPageInfo = {
  __typename?: 'TrainingClassPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** TrainingClassStatus Enum */
export enum TrainingClassStatus {
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Postponed = 'postponed',
  Provisional = 'provisional'
}

export type TrainingSeat = Node & {
  __typename?: 'TrainingSeat';
  _id: Scalars['String'];
  attendance?: Maybe<Attendance>;
  attendanceComments?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  seatNumber: Scalars['Int'];
  trainingSeatReservation?: Maybe<TrainingSeatReservation>;
  trainingSession?: Maybe<TrainingSession>;
};

/** Cursor connection for TrainingSeat. */
export type TrainingSeatCursorConnection = {
  __typename?: 'TrainingSeatCursorConnection';
  edges?: Maybe<Array<Maybe<TrainingSeatEdge>>>;
  pageInfo: TrainingSeatPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainingSeat. */
export type TrainingSeatEdge = {
  __typename?: 'TrainingSeatEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainingSeat>;
};

/** Information about the current page. */
export type TrainingSeatPageInfo = {
  __typename?: 'TrainingSeatPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type TrainingSeatReservation = Node & {
  __typename?: 'TrainingSeatReservation';
  _id: Scalars['String'];
  attendee?: Maybe<Attendee>;
  bookingItem?: Maybe<BookingItem>;
  id: Scalars['ID'];
  totalAttendance: Attendance;
  trainingSeats?: Maybe<TrainingSeatCursorConnection>;
};


export type TrainingSeatReservationTrainingSeatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  trainingSession?: InputMaybe<Scalars['String']>;
};

/** Cursor connection for TrainingSeatReservation. */
export type TrainingSeatReservationCursorConnection = {
  __typename?: 'TrainingSeatReservationCursorConnection';
  edges?: Maybe<Array<Maybe<TrainingSeatReservationEdge>>>;
  pageInfo: TrainingSeatReservationPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainingSeatReservation. */
export type TrainingSeatReservationEdge = {
  __typename?: 'TrainingSeatReservationEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainingSeatReservation>;
};

/** Information about the current page. */
export type TrainingSeatReservationPageInfo = {
  __typename?: 'TrainingSeatReservationPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type TrainingSession = Node & {
  __typename?: 'TrainingSession';
  _id: Scalars['String'];
  class?: Maybe<TrainingClass>;
  createdAt: Scalars['String'];
  endsAt: Scalars['String'];
  heliosLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacyId?: Maybe<Scalars['String']>;
  meetingLink?: Maybe<Scalars['String']>;
  seats?: Maybe<TrainingSeatCursorConnection>;
  seatsFreeCount: Scalars['Int'];
  seatsReservedCount: Scalars['Int'];
  seatsTotalCount: Scalars['Int'];
  startsAt: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type TrainingSessionSeatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  trainingSession?: InputMaybe<Scalars['String']>;
};

/** Cursor connection for TrainingSession. */
export type TrainingSessionCursorConnection = {
  __typename?: 'TrainingSessionCursorConnection';
  edges?: Maybe<Array<Maybe<TrainingSessionEdge>>>;
  pageInfo: TrainingSessionPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of TrainingSession. */
export type TrainingSessionEdge = {
  __typename?: 'TrainingSessionEdge';
  cursor: Scalars['String'];
  node?: Maybe<TrainingSession>;
};

export type TrainingSessionFilter_Order = {
  startsAt?: InputMaybe<Scalars['String']>;
};

export type TrainingSessionFilter_StartsAt = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  strictly_after?: InputMaybe<Scalars['String']>;
  strictly_before?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type TrainingSessionPageInfo = {
  __typename?: 'TrainingSessionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type UnlayerMergeTags = Node & {
  __typename?: 'UnlayerMergeTags';
  id: Scalars['ID'];
  resourceName: Scalars['String'];
  tags: Scalars['Iterable'];
};

export type User = Node & {
  __typename?: 'User';
  _id: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  person?: Maybe<Person>;
  roles: Scalars['Iterable'];
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};

/** Cursor connection for User. */
export type UserCursorConnection = {
  __typename?: 'UserCursorConnection';
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: UserPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of User. */
export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
};

export type UserFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['String']>;
  person_email?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type UserPageInfo = {
  __typename?: 'UserPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Workflow = Node & {
  __typename?: 'Workflow';
  _id: Scalars['String'];
  actionCount: Scalars['Int'];
  actions?: Maybe<WorkflowActionCursorConnection>;
  active: Scalars['Boolean'];
  condition?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  name: Scalars['String'];
  targetEvents: Scalars['Iterable'];
  targetResource: TargetResource;
  updatedAt: Scalars['String'];
  updatedBy?: Maybe<User>;
};


export type WorkflowActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WorkflowAction = Node & {
  __typename?: 'WorkflowAction';
  _id: Scalars['String'];
  actionType: WorkflowActionType;
  emailTemplate?: Maybe<EmailTemplate>;
  id: Scalars['ID'];
  targetContactTypes: Scalars['Iterable'];
  workflow?: Maybe<Workflow>;
};

/** Cursor connection for WorkflowAction. */
export type WorkflowActionCursorConnection = {
  __typename?: 'WorkflowActionCursorConnection';
  edges?: Maybe<Array<Maybe<WorkflowActionEdge>>>;
  pageInfo: WorkflowActionPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of WorkflowAction. */
export type WorkflowActionEdge = {
  __typename?: 'WorkflowActionEdge';
  cursor: Scalars['String'];
  node?: Maybe<WorkflowAction>;
};

/** Information about the current page. */
export type WorkflowActionPageInfo = {
  __typename?: 'WorkflowActionPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

/** WorkflowActionType Enum */
export enum WorkflowActionType {
  Email = 'email'
}

/** Cursor connection for Workflow. */
export type WorkflowCursorConnection = {
  __typename?: 'WorkflowCursorConnection';
  edges?: Maybe<Array<Maybe<WorkflowEdge>>>;
  pageInfo: WorkflowPageInfo;
  totalCount: Scalars['Int'];
};

/** Edge of Workflow. */
export type WorkflowEdge = {
  __typename?: 'WorkflowEdge';
  cursor: Scalars['String'];
  node?: Maybe<Workflow>;
};

export type WorkflowErrorLog = Node & {
  __typename?: 'WorkflowErrorLog';
  _id: Scalars['String'];
  context: Scalars['Iterable'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  trace: Scalars['String'];
  updatedAt: Scalars['String'];
  workflow?: Maybe<Workflow>;
  workflowAction?: Maybe<WorkflowAction>;
};

export type WorkflowFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  targetResource?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Information about the current page. */
export type WorkflowPageInfo = {
  __typename?: 'WorkflowPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type WorkflowResource = Node & {
  __typename?: 'WorkflowResource';
  _id: Scalars['String'];
  contactTypes: Scalars['Iterable'];
  events: Scalars['Iterable'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Acknowledges a PurchaseOrder. */
export type AcknowledgePurchaseOrderInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Acknowledges a PurchaseOrder. */
export type AcknowledgePurchaseOrderPayload = {
  __typename?: 'acknowledgePurchaseOrderPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<PurchaseOrder>;
};

/** AssignAttendeess a TrainingClass. */
export type AssignAttendeesTrainingClassInput = {
  attendees: Array<PersonInputType>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  replaceExisting?: InputMaybe<Scalars['Boolean']>;
};

/** AssignAttendeess a TrainingClass. */
export type AssignAttendeesTrainingClassPayload = {
  __typename?: 'assignAttendeesTrainingClassPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingClass?: Maybe<TrainingClass>;
};

/** AssignCustomerContacts a Booking. */
export type AssignCustomerContactBookingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerContact: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

/** AssignCustomerContacts a Booking. */
export type AssignCustomerContactBookingPayload = {
  __typename?: 'assignCustomerContactBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Assigns a TrainingSeat. */
export type AssignTrainingSeatInput = {
  attendee: PersonInputType;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Assigns a TrainingSeat. */
export type AssignTrainingSeatPayload = {
  __typename?: 'assignTrainingSeatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeat?: Maybe<TrainingSeat>;
};

/** Builds a EmailBuildContext. */
export type BuildEmailBuildContextInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Builds a EmailBuildContext. */
export type BuildEmailBuildContextPayload = {
  __typename?: 'buildEmailBuildContextPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailBuildContext?: Maybe<BuildEmailBuildContextPayloadData>;
};

/** Builds a EmailBuildContext. */
export type BuildEmailBuildContextPayloadData = Node & {
  __typename?: 'buildEmailBuildContextPayloadData';
  _id: Scalars['String'];
  id: Scalars['ID'];
};

/** Cancels a BookingItem. */
export type CancelBookingItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

/** Cancels a BookingItem. */
export type CancelBookingItemPayload = {
  __typename?: 'cancelBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Cancels a ChangeRequest. */
export type CancelChangeRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Cancels a ChangeRequest. */
export type CancelChangeRequestPayload = {
  __typename?: 'cancelChangeRequestPayload';
  changeRequest?: Maybe<ChangeRequest>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Cancels a PublicSchedule. */
export type CancelPublicScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Cancels a PublicSchedule. */
export type CancelPublicSchedulePayload = {
  __typename?: 'cancelPublicSchedulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  publicSchedule?: Maybe<PublicSchedule>;
};

/** Confirms a Booking. */
export type ConfirmBookingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Confirms a Booking. */
export type ConfirmBookingPayload = {
  __typename?: 'confirmBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** ConvertToTaxInvoices a Invoice. */
export type ConvertToTaxInvoiceInvoiceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** ConvertToTaxInvoices a Invoice. */
export type ConvertToTaxInvoiceInvoicePayload = {
  __typename?: 'convertToTaxInvoiceInvoicePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Creates a Account. */
export type CreateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  internal: Scalars['Boolean'];
  name: Scalars['String'];
  organisation?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Creates a Account. */
export type CreateAccountPayload = {
  __typename?: 'createAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Attendee. */
export type CreateAttendeeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerContact?: InputMaybe<Scalars['String']>;
  receiveNewsAndUpdates?: InputMaybe<Scalars['Boolean']>;
  reservation?: InputMaybe<Scalars['String']>;
  reviewedOutline?: InputMaybe<Scalars['Iterable']>;
  useInMarketing?: InputMaybe<Scalars['Boolean']>;
};

/** Creates a Attendee. */
export type CreateAttendeePayload = {
  __typename?: 'createAttendeePayload';
  attendee?: Maybe<Attendee>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Booking. */
export type CreateBookingInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  billingAddress: AddressInput;
  billingContact?: InputMaybe<Scalars['String']>;
  bookedAt: Scalars['String'];
  booker?: InputMaybe<Scalars['String']>;
  brand: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  eventLogs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expectedTotalValue: MoneyInput;
  items?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  onlyTaxInvoiceBillable: Scalars['Boolean'];
  paymentTermDays: Scalars['Int'];
  preferredPaymentMethod: PaymentMethod;
  title: Scalars['String'];
  webBookingReference?: InputMaybe<Scalars['String']>;
};

/** Creates a BookingItem. */
export type CreateBookingItemInput = {
  attendees?: InputMaybe<Scalars['Iterable']>;
  booking?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  deliveryAddress: AddressInput;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  description?: InputMaybe<Scalars['String']>;
  invoiceNotes?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<InvoiceNestedInput>>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  poNumber: Scalars['String'];
  quantity: Scalars['String'];
  sessions: Scalars['Iterable'];
  tailored: Scalars['Boolean'];
  taxRatePercent: Scalars['String'];
  taxable: Scalars['Boolean'];
  title: Scalars['String'];
  trainer?: InputMaybe<Scalars['String']>;
  trainerDayRate?: InputMaybe<MoneyInput>;
  trainerDayRateAmount?: InputMaybe<Scalars['Int']>;
  trainerTravel?: InputMaybe<MoneyInput>;
  trainerTravelAmount?: InputMaybe<Scalars['Int']>;
  trainingSeatReservations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: Scalars['String'];
  unitPrice: MoneyInput;
};

/** Creates a BookingItem. */
export type CreateBookingItemNestedInput = {
  attendees?: InputMaybe<Scalars['Iterable']>;
  booking?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  deliveryAddress: AddressInput;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceNotes?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<InvoiceNestedInput>>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  poNumber: Scalars['String'];
  quantity: Scalars['String'];
  sessions: Scalars['Iterable'];
  tailored: Scalars['Boolean'];
  taxRatePercent: Scalars['String'];
  taxable: Scalars['Boolean'];
  title: Scalars['String'];
  trainer?: InputMaybe<Scalars['String']>;
  trainerDayRate?: InputMaybe<MoneyInput>;
  trainerDayRateAmount?: InputMaybe<Scalars['Int']>;
  trainerTravel?: InputMaybe<MoneyInput>;
  trainerTravelAmount?: InputMaybe<Scalars['Int']>;
  trainingSeatReservations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: Scalars['String'];
  unitPrice: MoneyInput;
};

/** Creates a BookingItem. */
export type CreateBookingItemPayload = {
  __typename?: 'createBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a BookingNote. */
export type CreateBookingNoteInput = {
  body: Scalars['String'];
  booking?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a BookingNote. */
export type CreateBookingNotePayload = {
  __typename?: 'createBookingNotePayload';
  bookingNote?: Maybe<BookingNote>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a Booking. */
export type CreateBookingPayload = {
  __typename?: 'createBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a ChangeRequest. */
export type CreateChangeRequestInput = {
  bookingItem: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveryAddress: AddressInput;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Scalars['Iterable']>;
  trainer?: InputMaybe<Scalars['String']>;
  trainerDayRate?: InputMaybe<MoneyInput>;
  trainerDayRateAmount?: InputMaybe<Scalars['Int']>;
  trainerTravel?: InputMaybe<MoneyInput>;
  trainerTravelAmount?: InputMaybe<Scalars['Int']>;
  trainingClass?: InputMaybe<Scalars['String']>;
  unitPriceAmount?: InputMaybe<Scalars['Int']>;
};

/** Creates a ChangeRequest. */
export type CreateChangeRequestPayload = {
  __typename?: 'createChangeRequestPayload';
  changeRequest?: Maybe<ChangeRequest>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a CourseVersion. */
export type CreateCourseVersionInput = {
  author?: InputMaybe<Scalars['String']>;
  authorReference?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  durationDays: Scalars['Float'];
  level?: InputMaybe<Scalars['String']>;
  outline: Scalars['Iterable'];
  prerequisites?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  publishedBy?: InputMaybe<Scalars['String']>;
  reference: Scalars['String'];
  setupRequirements?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Creates a CourseVersion. */
export type CreateCourseVersionPayload = {
  __typename?: 'createCourseVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  courseVersion?: Maybe<CourseVersion>;
};

/** Creates a CustomerContact. */
export type CreateCustomerContactInput = {
  bookingCustomerContacts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  roles: Scalars['Iterable'];
  telephone?: InputMaybe<Scalars['String']>;
};

/** Creates a CustomerContact. */
export type CreateCustomerContactPayload = {
  __typename?: 'createCustomerContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerContact?: Maybe<CustomerContact>;
};

/** Creates a Customer. */
export type CreateCustomerInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onlyTaxInvoiceBillable: Scalars['Boolean'];
  paymentTermDays: Scalars['Int'];
  preferredPaymentMethod: PaymentMethod;
  registrationNumber?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Creates a CustomerLocation. */
export type CreateCustomerLocationInput = {
  address: AddressInput;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
};

/** Creates a CustomerLocation. */
export type CreateCustomerLocationPayload = {
  __typename?: 'createCustomerLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerLocation?: Maybe<CustomerLocation>;
};

/** Creates a CustomerNote. */
export type CreateCustomerNoteInput = {
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
};

/** Creates a CustomerNote. */
export type CreateCustomerNotePayload = {
  __typename?: 'createCustomerNotePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerNote?: Maybe<CustomerNote>;
};

/** Creates a Customer. */
export type CreateCustomerPayload = {
  __typename?: 'createCustomerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
};

/** Creates a EmailBuildContext. */
export type CreateEmailBuildContextInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailTemplate?: InputMaybe<Scalars['String']>;
  forceResend: Scalars['Boolean'];
  targetContacts?: InputMaybe<Scalars['Iterable']>;
  targetResource: TargetResource;
  targetResourceId: Scalars['String'];
};

/** Creates a EmailBuildContext. */
export type CreateEmailBuildContextPayload = {
  __typename?: 'createEmailBuildContextPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailBuildContext?: Maybe<EmailBuildContext>;
};

/** Creates a EmailTemplate. */
export type CreateEmailTemplateInput = {
  brand?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  design: Scalars['String'];
  editor: EmailEditor;
  html: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
  targetContactCondition?: InputMaybe<Scalars['String']>;
  targetContactTypes: Scalars['Iterable'];
  targetResource: TargetResource;
};

/** Creates a EmailTemplate. */
export type CreateEmailTemplatePayload = {
  __typename?: 'createEmailTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
};

/** Creates a File. */
export type CreateFileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  current?: InputMaybe<Scalars['String']>;
  versions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Creates a File. */
export type CreateFilePayload = {
  __typename?: 'createFilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

/** Creates a FileVersion. */
export type CreateFileVersionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  originalFileName: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  revision: Scalars['Int'];
  size: Scalars['Int'];
};

/** Creates a FileVersion. */
export type CreateFileVersionPayload = {
  __typename?: 'createFileVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fileVersion?: Maybe<FileVersion>;
};

/** Creates a InternalContact. */
export type CreateInternalContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** Creates a InternalContact. */
export type CreateInternalContactPayload = {
  __typename?: 'createInternalContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  internalContact?: Maybe<InternalContact>;
};

/** Creates a InvoiceEventLog. */
export type CreateInvoiceEventLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a InvoiceEventLog. */
export type CreateInvoiceEventLogPayload = {
  __typename?: 'createInvoiceEventLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceEventLog?: Maybe<InvoiceEventLog>;
};

/** Creates a InvoiceItem. */
export type CreateInvoiceItemInput = {
  account?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Scalars['String']>;
  quantity: Scalars['String'];
  taxRatePercent: Scalars['String'];
  taxable: Scalars['Boolean'];
  unitPrice: MoneyInput;
  unitPriceAmount: Scalars['Int'];
  updatedAt: Scalars['String'];
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Creates a InvoiceItem. */
export type CreateInvoiceItemPayload = {
  __typename?: 'createInvoiceItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceItem>;
};

/** Creates a InvoicePayment. */
export type CreateInvoicePaymentInput = {
  amount: Scalars['Int'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoice: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  receivedAt: Scalars['String'];
};

/** Creates a InvoicePayment. */
export type CreateInvoicePaymentPayload = {
  __typename?: 'createInvoicePaymentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoicePayment?: Maybe<InvoicePayment>;
};

/** Creates a Location. */
export type CreateLocationInput = {
  address: AddressInput;
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a Location. */
export type CreateLocationPayload = {
  __typename?: 'createLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

/** Creates a NewPublicScheduleDates. */
export type CreateNewPublicScheduleDatesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  coursePrice: MoneyInput;
  courseVersion: Scalars['String'];
  dates: Scalars['Iterable'];
  deliveryFormat: Scalars['String'];
  deliveryPlatform?: InputMaybe<Scalars['String']>;
  maxAttendees: Scalars['Int'];
  trainer: Scalars['String'];
  trainerRate: MoneyInput;
};

/** Creates a NewPublicScheduleDates. */
export type CreateNewPublicScheduleDatesPayload = {
  __typename?: 'createNewPublicScheduleDatesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newPublicScheduleDates?: Maybe<NewPublicScheduleDates>;
};

/** Creates a NewSupplier. */
export type CreateNewSupplierInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankSortCode?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryContactIsTrainer: Scalars['Boolean'];
  purchasesEmail?: InputMaybe<Scalars['String']>;
  purchasesLocation: AddressInput;
  registrationNumber?: InputMaybe<Scalars['String']>;
  remittanceEmail?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Creates a NewSupplier. */
export type CreateNewSupplierPayload = {
  __typename?: 'createNewSupplierPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newSupplier?: Maybe<NewSupplier>;
};

/** Creates a NewTrainingClass. */
export type CreateNewTrainingClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  course: Scalars['String'];
  deliveryAddress: AddressInput;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  public?: InputMaybe<Scalars['Boolean']>;
  sessions: Scalars['Iterable'];
  title: Scalars['String'];
  trainer: Scalars['String'];
};

/** Creates a NewTrainingClass. */
export type CreateNewTrainingClassPayload = {
  __typename?: 'createNewTrainingClassPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newTrainingClass?: Maybe<NewTrainingClass>;
};

/** Creates a NewUser. */
export type CreateNewUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactType: Scalars['String'];
  email: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  roles: Scalars['Iterable'];
  telephone?: InputMaybe<Scalars['String']>;
};

/** Creates a NewUser. */
export type CreateNewUserPayload = {
  __typename?: 'createNewUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newUser?: Maybe<NewUser>;
};

/** Creates a Organisation. */
export type CreateOrganisationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  reference: Scalars['String'];
  registrationNumber?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Creates a Organisation. */
export type CreateOrganisationPayload = {
  __typename?: 'createOrganisationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
};

/** Creates a OrganisationType. */
export type CreateOrganisationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** Creates a OrganisationType. */
export type CreateOrganisationTypePayload = {
  __typename?: 'createOrganisationTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisationType?: Maybe<OrganisationType>;
};

/** Creates a Parameter. */
export type CreateParameterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  path: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

/** Creates a Parameter. */
export type CreateParameterPayload = {
  __typename?: 'createParameterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
};

/** Creates a Person. */
export type CreatePersonInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** Creates a Person. */
export type CreatePersonPayload = {
  __typename?: 'createPersonPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
};

/** CreatePublicCourses a Booking. */
export type CreatePublicCourseBookingInput = {
  attendees?: InputMaybe<Scalars['Iterable']>;
  booking: Scalars['ID'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  invoiceNotes?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  taxRatePercent: Scalars['Int'];
  taxable: Scalars['Boolean'];
  trainingSession: Scalars['ID'];
  unitPrice: Scalars['Int'];
};

/** CreatePublicCourses a Booking. */
export type CreatePublicCourseBookingPayload = {
  __typename?: 'createPublicCourseBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Creates a PurchaseOrderEvent. */
export type CreatePurchaseOrderEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a PurchaseOrderEvent. */
export type CreatePurchaseOrderEventPayload = {
  __typename?: 'createPurchaseOrderEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderEvent?: Maybe<PurchaseOrderEvent>;
};

/** Creates a PurchaseOrderItem. */
export type CreatePurchaseOrderItemInput = {
  bookingItem: CreateBookingItemNestedInput;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  publicSchedule?: InputMaybe<Scalars['String']>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  quantity: Scalars['String'];
  taxRatePercent: Scalars['String'];
  taxable: Scalars['Boolean'];
  unitPrice: MoneyInput;
};

/** Creates a PurchaseOrderItem. */
export type CreatePurchaseOrderItemPayload = {
  __typename?: 'createPurchaseOrderItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<PurchaseOrderItem>;
};

/** CreatePurchaseOrders a PublicSchedule. */
export type CreatePurchaseOrderPublicScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** CreatePurchaseOrders a PublicSchedule. */
export type CreatePurchaseOrderPublicSchedulePayload = {
  __typename?: 'createPurchaseOrderPublicSchedulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  publicSchedule?: Maybe<PublicSchedule>;
};

/** Creates a Subject. */
export type CreateSubjectInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  courses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postCourseQuestionaire?: InputMaybe<Scalars['String']>;
  preCourseQuestionaire?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Creates a Subject. */
export type CreateSubjectPayload = {
  __typename?: 'createSubjectPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  subject?: Maybe<Subject>;
};

/** Creates a SupplierContact. */
export type CreateSupplierContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Creates a SupplierContact. */
export type CreateSupplierContactPayload = {
  __typename?: 'createSupplierContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierContact?: Maybe<SupplierContact>;
};

/** Creates a Supplier. */
export type CreateSupplierInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankSortCode?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  primaryContact?: InputMaybe<Scalars['String']>;
  purchasesEmail?: InputMaybe<Scalars['String']>;
  purchasesLocation?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  remittanceEmail?: InputMaybe<Scalars['String']>;
  trainers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: Scalars['String'];
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Creates a SupplierLocation. */
export type CreateSupplierLocationInput = {
  address: AddressInput;
  clientMutationId?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
};

/** Creates a SupplierLocation. */
export type CreateSupplierLocationPayload = {
  __typename?: 'createSupplierLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierLocation?: Maybe<SupplierLocation>;
};

/** Creates a SupplierNote. */
export type CreateSupplierNoteInput = {
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
};

/** Creates a SupplierNote. */
export type CreateSupplierNotePayload = {
  __typename?: 'createSupplierNotePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierNote?: Maybe<SupplierNote>;
};

/** Creates a Supplier. */
export type CreateSupplierPayload = {
  __typename?: 'createSupplierPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
};

/** Creates a TicketActivity. */
export type CreateTicketActivityInput = {
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  ticket?: InputMaybe<Scalars['String']>;
};

/** Creates a TicketActivity. */
export type CreateTicketActivityPayload = {
  __typename?: 'createTicketActivityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ticketActivity?: Maybe<TicketActivity>;
};

/** Creates a Ticket. */
export type CreateTicketInput = {
  body: Scalars['String'];
  booking?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  status: TicketStatus;
  subject: Scalars['String'];
};

/** Creates a Ticket. */
export type CreateTicketPayload = {
  __typename?: 'createTicketPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ticket?: Maybe<Ticket>;
};

/** Creates a TrainerCourse. */
export type CreateTrainerCourseInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  competency: Scalars['String'];
  competencySelf?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  rate: MoneyInput;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Creates a TrainerCourse. */
export type CreateTrainerCoursePayload = {
  __typename?: 'createTrainerCoursePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainerCourse?: Maybe<TrainerCourse>;
};

/** Creates a Trainer. */
export type CreateTrainerInput = {
  calendarUrl?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  supplier?: InputMaybe<Scalars['String']>;
  trainerCourses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trainingClasses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Creates a Trainer. */
export type CreateTrainerPayload = {
  __typename?: 'createTrainerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainer?: Maybe<Trainer>;
};

/** Creates a TrainingClassNote. */
export type CreateTrainingClassNoteInput = {
  body: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  trainingClass?: InputMaybe<Scalars['String']>;
};

/** Creates a TrainingClassNote. */
export type CreateTrainingClassNotePayload = {
  __typename?: 'createTrainingClassNotePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingClassNote?: Maybe<TrainingClassNote>;
};

/** Creates a TrainingSeatReservation. */
export type CreateTrainingSeatReservationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a TrainingSeatReservation. */
export type CreateTrainingSeatReservationPayload = {
  __typename?: 'createTrainingSeatReservationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeatReservation?: Maybe<TrainingSeatReservation>;
};

/** Creates a User. */
export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<Scalars['String']>;
  roles: Scalars['Iterable'];
};

/** Creates a User. */
export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Creates a WorkflowAction. */
export type CreateWorkflowActionInput = {
  actionType: WorkflowActionType;
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailTemplate?: InputMaybe<Scalars['String']>;
  targetContactTypes: Scalars['Iterable'];
  workflow?: InputMaybe<Scalars['String']>;
};

/** Creates a WorkflowAction. */
export type CreateWorkflowActionPayload = {
  __typename?: 'createWorkflowActionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowAction?: Maybe<WorkflowAction>;
};

/** Creates a WorkflowErrorLog. */
export type CreateWorkflowErrorLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Creates a WorkflowErrorLog. */
export type CreateWorkflowErrorLogPayload = {
  __typename?: 'createWorkflowErrorLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowErrorLog?: Maybe<WorkflowErrorLog>;
};

/** Creates a Workflow. */
export type CreateWorkflowInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  active: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  targetEvents: Scalars['Iterable'];
  targetResource: TargetResource;
};

/** Creates a Workflow. */
export type CreateWorkflowPayload = {
  __typename?: 'createWorkflowPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflow?: Maybe<Workflow>;
};

/** Deletes a Account. */
export type DeleteAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Account. */
export type DeleteAccountPayload = {
  __typename?: 'deleteAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a BookingCustomerContact. */
export type DeleteBookingCustomerContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a BookingCustomerContact. */
export type DeleteBookingCustomerContactPayload = {
  __typename?: 'deleteBookingCustomerContactPayload';
  bookingCustomerContact?: Maybe<BookingCustomerContact>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a Booking. */
export type DeleteBookingInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a BookingItem. */
export type DeleteBookingItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a BookingItem. */
export type DeleteBookingItemPayload = {
  __typename?: 'deleteBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a Booking. */
export type DeleteBookingPayload = {
  __typename?: 'deleteBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Deletes a Course. */
export type DeleteCourseInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Course. */
export type DeleteCoursePayload = {
  __typename?: 'deleteCoursePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
};

/** Deletes a CourseVersion. */
export type DeleteCourseVersionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a CourseVersion. */
export type DeleteCourseVersionPayload = {
  __typename?: 'deleteCourseVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  courseVersion?: Maybe<CourseVersion>;
};

/** Deletes a CustomerLocation. */
export type DeleteCustomerLocationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a CustomerLocation. */
export type DeleteCustomerLocationPayload = {
  __typename?: 'deleteCustomerLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerLocation?: Maybe<CustomerLocation>;
};

/** Deletes a File. */
export type DeleteFileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a File. */
export type DeleteFilePayload = {
  __typename?: 'deleteFilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

/** Deletes a FileVersion. */
export type DeleteFileVersionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a FileVersion. */
export type DeleteFileVersionPayload = {
  __typename?: 'deleteFileVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fileVersion?: Maybe<FileVersion>;
};

/** Deletes a InternalContact. */
export type DeleteInternalContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a InternalContact. */
export type DeleteInternalContactPayload = {
  __typename?: 'deleteInternalContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  internalContact?: Maybe<InternalContact>;
};

/** Deletes a InvoiceEventLog. */
export type DeleteInvoiceEventLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a InvoiceEventLog. */
export type DeleteInvoiceEventLogPayload = {
  __typename?: 'deleteInvoiceEventLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceEventLog?: Maybe<InvoiceEventLog>;
};

/** Deletes a InvoiceItem. */
export type DeleteInvoiceItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a InvoiceItem. */
export type DeleteInvoiceItemPayload = {
  __typename?: 'deleteInvoiceItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceItem>;
};

/** Deletes a Location. */
export type DeleteLocationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Location. */
export type DeleteLocationPayload = {
  __typename?: 'deleteLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

/** Deletes a NewPublicScheduleDates. */
export type DeleteNewPublicScheduleDatesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a NewPublicScheduleDates. */
export type DeleteNewPublicScheduleDatesPayload = {
  __typename?: 'deleteNewPublicScheduleDatesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newPublicScheduleDates?: Maybe<NewPublicScheduleDates>;
};

/** Deletes a NewTrainingClass. */
export type DeleteNewTrainingClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a NewTrainingClass. */
export type DeleteNewTrainingClassPayload = {
  __typename?: 'deleteNewTrainingClassPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newTrainingClass?: Maybe<NewTrainingClass>;
};

/** Deletes a Organisation. */
export type DeleteOrganisationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Organisation. */
export type DeleteOrganisationPayload = {
  __typename?: 'deleteOrganisationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
};

/** Deletes a OrganisationType. */
export type DeleteOrganisationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a OrganisationType. */
export type DeleteOrganisationTypePayload = {
  __typename?: 'deleteOrganisationTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisationType?: Maybe<OrganisationType>;
};

/** Deletes a Parameter. */
export type DeleteParameterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Parameter. */
export type DeleteParameterPayload = {
  __typename?: 'deleteParameterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
};

/** Deletes a Person. */
export type DeletePersonInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Person. */
export type DeletePersonPayload = {
  __typename?: 'deletePersonPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
};

/** Deletes a PurchaseOrderEvent. */
export type DeletePurchaseOrderEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a PurchaseOrderEvent. */
export type DeletePurchaseOrderEventPayload = {
  __typename?: 'deletePurchaseOrderEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderEvent?: Maybe<PurchaseOrderEvent>;
};

/** Deletes a PurchaseOrderItem. */
export type DeletePurchaseOrderItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a PurchaseOrderItem. */
export type DeletePurchaseOrderItemPayload = {
  __typename?: 'deletePurchaseOrderItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<PurchaseOrderItem>;
};

/** Deletes a SupplierLocation. */
export type DeleteSupplierLocationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a SupplierLocation. */
export type DeleteSupplierLocationPayload = {
  __typename?: 'deleteSupplierLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierLocation?: Maybe<SupplierLocation>;
};

/** Deletes a TrainerCourse. */
export type DeleteTrainerCourseInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a TrainerCourse. */
export type DeleteTrainerCoursePayload = {
  __typename?: 'deleteTrainerCoursePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainerCourse?: Maybe<TrainerCourse>;
};

/** Deletes a TrainingSeatReservation. */
export type DeleteTrainingSeatReservationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a TrainingSeatReservation. */
export type DeleteTrainingSeatReservationPayload = {
  __typename?: 'deleteTrainingSeatReservationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeatReservation?: Maybe<TrainingSeatReservation>;
};

/** Deletes a WorkflowAction. */
export type DeleteWorkflowActionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a WorkflowAction. */
export type DeleteWorkflowActionPayload = {
  __typename?: 'deleteWorkflowActionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowAction?: Maybe<WorkflowAction>;
};

/** Deletes a WorkflowErrorLog. */
export type DeleteWorkflowErrorLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a WorkflowErrorLog. */
export type DeleteWorkflowErrorLogPayload = {
  __typename?: 'deleteWorkflowErrorLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowErrorLog?: Maybe<WorkflowErrorLog>;
};

/** Deletes a Workflow. */
export type DeleteWorkflowInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Deletes a Workflow. */
export type DeleteWorkflowPayload = {
  __typename?: 'deleteWorkflowPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflow?: Maybe<Workflow>;
};

/** Implements a ChangeRequest. */
export type ImplementChangeRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Implements a ChangeRequest. */
export type ImplementChangeRequestPayload = {
  __typename?: 'implementChangeRequestPayload';
  changeRequest?: Maybe<ChangeRequest>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Rejects a ChangeRequest. */
export type RejectChangeRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  rejectedReason?: InputMaybe<Scalars['String']>;
};

/** Rejects a ChangeRequest. */
export type RejectChangeRequestPayload = {
  __typename?: 'rejectChangeRequestPayload';
  changeRequest?: Maybe<ChangeRequest>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Resends a Email. */
export type ResendEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  to?: InputMaybe<Array<Scalars['String']>>;
};

/** Resends a Email. */
export type ResendEmailPayload = {
  __typename?: 'resendEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
};

/** Sends a EmailBuildContext. */
export type SendEmailBuildContextInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Sends a EmailBuildContext. */
export type SendEmailBuildContextPayload = {
  __typename?: 'sendEmailBuildContextPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailBuildContext?: Maybe<EmailBuildContext>;
};

/** SubstituteTrainers a BookingItem. */
export type SubstituteTrainerBookingItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dayRate: Scalars['Int'];
  id: Scalars['ID'];
  trainer: Scalars['ID'];
  travelRate?: InputMaybe<Scalars['Int']>;
};

/** SubstituteTrainers a BookingItem. */
export type SubstituteTrainerBookingItemPayload = {
  __typename?: 'substituteTrainerBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** SubstituteTrainers a PublicSchedule. */
export type SubstituteTrainerPublicScheduleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  dayRate: Scalars['Int'];
  id: Scalars['ID'];
  trainer: Scalars['ID'];
};

/** SubstituteTrainers a PublicSchedule. */
export type SubstituteTrainerPublicSchedulePayload = {
  __typename?: 'substituteTrainerPublicSchedulePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  publicSchedule?: Maybe<PublicSchedule>;
};

/** Unassigns a TrainingSeat. */
export type UnassignTrainingSeatInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Unassigns a TrainingSeat. */
export type UnassignTrainingSeatPayload = {
  __typename?: 'unassignTrainingSeatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeat?: Maybe<TrainingSeat>;
};

/** Updates a Account. */
export type UpdateAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  internal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Updates a Account. */
export type UpdateAccountPayload = {
  __typename?: 'updateAccountPayload';
  account?: Maybe<Account>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** UpdateAlls a Email. */
export type UpdateAllEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  toBeSent: Scalars['Boolean'];
};

/** UpdateAlls a Email. */
export type UpdateAllEmailPayload = {
  __typename?: 'updateAllEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
};

/** UpdateBillingAddressAndPONumbers a Invoice. */
export type UpdateBillingAddressAndPoNumberInvoiceInput = {
  billingAddress?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  poNumber?: InputMaybe<Scalars['String']>;
};

/** UpdateBillingAddressAndPONumbers a Invoice. */
export type UpdateBillingAddressAndPoNumberInvoicePayload = {
  __typename?: 'updateBillingAddressAndPONumberInvoicePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Updates a Booking. */
export type UpdateBookingInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<AddressInput>;
  billingContact?: InputMaybe<Scalars['String']>;
  bookedAt?: InputMaybe<Scalars['String']>;
  booker?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  eventLogs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  expectedTotalValue?: InputMaybe<MoneyInput>;
  id: Scalars['ID'];
  items?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  title?: InputMaybe<Scalars['String']>;
  webBookingReference?: InputMaybe<Scalars['String']>;
};

/** Updates a BookingItem. */
export type UpdateBookingItemInput = {
  attendees?: InputMaybe<Scalars['Iterable']>;
  booking?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceNotes?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<UpdateInvoiceNestedInput>>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  poNumber?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Scalars['Iterable']>;
  tailored?: InputMaybe<Scalars['Boolean']>;
  taxRatePercent?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  trainer?: InputMaybe<Scalars['String']>;
  trainerDayRate?: InputMaybe<MoneyInput>;
  trainerDayRateAmount?: InputMaybe<Scalars['Int']>;
  trainerTravel?: InputMaybe<MoneyInput>;
  trainerTravelAmount?: InputMaybe<Scalars['Int']>;
  trainingSeatReservations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<MoneyInput>;
};

/** Updates a BookingItem. */
export type UpdateBookingItemNestedInput = {
  attendees?: InputMaybe<Scalars['Iterable']>;
  booking?: InputMaybe<Scalars['String']>;
  class?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  invoiceNotes?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Array<UpdateInvoiceNestedInput>>;
  maxAttendees?: InputMaybe<Scalars['Int']>;
  poNumber?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<Scalars['Iterable']>;
  tailored?: InputMaybe<Scalars['Boolean']>;
  taxRatePercent?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  trainer?: InputMaybe<Scalars['String']>;
  trainerDayRate?: InputMaybe<MoneyInput>;
  trainerDayRateAmount?: InputMaybe<Scalars['Int']>;
  trainerTravel?: InputMaybe<MoneyInput>;
  trainerTravelAmount?: InputMaybe<Scalars['Int']>;
  trainingSeatReservations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<MoneyInput>;
};

/** Updates a BookingItem. */
export type UpdateBookingItemPayload = {
  __typename?: 'updateBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Booking. */
export type UpdateBookingPayload = {
  __typename?: 'updateBookingPayload';
  booking?: Maybe<Booking>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a ChangeRequestAction. */
export type UpdateChangeRequestActionInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a ChangeRequestAction. */
export type UpdateChangeRequestActionPayload = {
  __typename?: 'updateChangeRequestActionPayload';
  changeRequestAction?: Maybe<ChangeRequestAction>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a Course. */
export type UpdateCourseInput = {
  assets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  coursesVersions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  latestCourseVersion?: InputMaybe<Scalars['String']>;
  publishedCourseVersion?: InputMaybe<Scalars['String']>;
  trainerCourses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a Course. */
export type UpdateCoursePayload = {
  __typename?: 'updateCoursePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
};

/** Updates a CourseVersion. */
export type UpdateCourseVersionInput = {
  author?: InputMaybe<Scalars['String']>;
  authorReference?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  durationDays?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  level?: InputMaybe<Scalars['String']>;
  outline?: InputMaybe<Scalars['Iterable']>;
  prerequisites?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['String']>;
  publishedBy?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  setupRequirements?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Updates a CourseVersion. */
export type UpdateCourseVersionPayload = {
  __typename?: 'updateCourseVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  courseVersion?: Maybe<CourseVersion>;
};

/** Updates a CustomerContact. */
export type UpdateCustomerContactInput = {
  bookingCustomerContacts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['Iterable']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** Updates a CustomerContact. */
export type UpdateCustomerContactPayload = {
  __typename?: 'updateCustomerContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerContact?: Maybe<CustomerContact>;
};

/** Updates a Customer. */
export type UpdateCustomerInput = {
  accountManager?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  onlyTaxInvoiceBillable?: InputMaybe<Scalars['Boolean']>;
  paymentTermDays?: InputMaybe<Scalars['Int']>;
  preferredPaymentMethod?: InputMaybe<PaymentMethod>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Updates a CustomerLocation. */
export type UpdateCustomerLocationInput = {
  address?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a CustomerLocation. */
export type UpdateCustomerLocationPayload = {
  __typename?: 'updateCustomerLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customerLocation?: Maybe<CustomerLocation>;
};

/** Updates a Customer. */
export type UpdateCustomerPayload = {
  __typename?: 'updateCustomerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customer?: Maybe<Customer>;
};

/** UpdateDocumentDateAndNotess a Invoice. */
export type UpdateDocumentDateAndNotesInvoiceInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentDate?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};

/** UpdateDocumentDateAndNotess a Invoice. */
export type UpdateDocumentDateAndNotesInvoicePayload = {
  __typename?: 'updateDocumentDateAndNotesInvoicePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Updates a Email. */
export type UpdateEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  toBeSent?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a Email. */
export type UpdateEmailPayload = {
  __typename?: 'updateEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Email>;
};

/** Updates a EmailTemplate. */
export type UpdateEmailTemplateInput = {
  brand?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<Scalars['String']>;
  html?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  targetContactCondition?: InputMaybe<Scalars['String']>;
  targetContactTypes?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a EmailTemplate. */
export type UpdateEmailTemplatePayload = {
  __typename?: 'updateEmailTemplatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<EmailTemplate>;
};

/** Updates a File. */
export type UpdateFileInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  current?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  versions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a File. */
export type UpdateFilePayload = {
  __typename?: 'updateFilePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
};

/** Updates a FileVersion. */
export type UpdateFileVersionInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mimeType?: InputMaybe<Scalars['String']>;
  originalFileName?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** Updates a FileVersion. */
export type UpdateFileVersionPayload = {
  __typename?: 'updateFileVersionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  fileVersion?: Maybe<FileVersion>;
};

/** Updates a InternalContact. */
export type UpdateInternalContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** Updates a InternalContact. */
export type UpdateInternalContactPayload = {
  __typename?: 'updateInternalContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  internalContact?: Maybe<InternalContact>;
};

/** Updates a InvoiceEventLog. */
export type UpdateInvoiceEventLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a InvoiceEventLog. */
export type UpdateInvoiceEventLogPayload = {
  __typename?: 'updateInvoiceEventLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceEventLog?: Maybe<InvoiceEventLog>;
};

/** Updates a Invoice. */
export type UpdateInvoiceInput = {
  billingAddress?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentDate?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
};

/** Updates a InvoiceItem. */
export type UpdateInvoiceItemInput = {
  account?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  taxRatePercent?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<MoneyInput>;
  unitPriceAmount?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Updates a InvoiceItem. */
export type UpdateInvoiceItemPayload = {
  __typename?: 'updateInvoiceItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceItem?: Maybe<InvoiceItem>;
};

/** Updates a Invoice. */
export type UpdateInvoiceNestedInput = {
  billingAddress?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentDate?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  poNumber?: InputMaybe<Scalars['String']>;
};

/** Updates a Invoice. */
export type UpdateInvoicePayload = {
  __typename?: 'updateInvoicePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
};

/** Updates a Location. */
export type UpdateLocationInput = {
  address?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a Location. */
export type UpdateLocationPayload = {
  __typename?: 'updateLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

/** UpdateMeetingLinks a TrainingSession. */
export type UpdateMeetingLinkTrainingSessionInput = {
  applyAll?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  meetingLink: Scalars['String'];
};

/** UpdateMeetingLinks a TrainingSession. */
export type UpdateMeetingLinkTrainingSessionPayload = {
  __typename?: 'updateMeetingLinkTrainingSessionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSession?: Maybe<TrainingSession>;
};

/** Updates a NewPublicScheduleDates. */
export type UpdateNewPublicScheduleDatesInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  coursePrice?: InputMaybe<MoneyInput>;
  courseVersion?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Scalars['Iterable']>;
  deliveryFormat?: InputMaybe<Scalars['String']>;
  deliveryPlatform?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  maxAttendees?: InputMaybe<Scalars['Int']>;
  trainer?: InputMaybe<Scalars['String']>;
  trainerRate?: InputMaybe<MoneyInput>;
};

/** Updates a NewPublicScheduleDates. */
export type UpdateNewPublicScheduleDatesPayload = {
  __typename?: 'updateNewPublicScheduleDatesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newPublicScheduleDates?: Maybe<NewPublicScheduleDates>;
};

/** Updates a NewTrainingClass. */
export type UpdateNewTrainingClassInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  deliveryAddress?: InputMaybe<AddressInput>;
  deliveryFormat?: InputMaybe<DeliveryFormat>;
  deliveryPlatform?: InputMaybe<DeliveryPlatform>;
  id: Scalars['ID'];
  maxAttendees?: InputMaybe<Scalars['Int']>;
  public?: InputMaybe<Scalars['Boolean']>;
  sessions?: InputMaybe<Scalars['Iterable']>;
  title?: InputMaybe<Scalars['String']>;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Updates a NewTrainingClass. */
export type UpdateNewTrainingClassPayload = {
  __typename?: 'updateNewTrainingClassPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  newTrainingClass?: Maybe<NewTrainingClass>;
};

/** Updates a Organisation. */
export type UpdateOrganisationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  xeroId?: InputMaybe<Scalars['String']>;
  xeroLastSyncedAt?: InputMaybe<Scalars['String']>;
};

/** Updates a Organisation. */
export type UpdateOrganisationPayload = {
  __typename?: 'updateOrganisationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
};

/** Updates a OrganisationType. */
export type UpdateOrganisationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

/** Updates a OrganisationType. */
export type UpdateOrganisationTypePayload = {
  __typename?: 'updateOrganisationTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organisationType?: Maybe<OrganisationType>;
};

/** Updates a Parameter. */
export type UpdateParameterInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  path?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Updates a Parameter. */
export type UpdateParameterPayload = {
  __typename?: 'updateParameterPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
};

/** Updates a Person. */
export type UpdatePersonInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** Updates a Person. */
export type UpdatePersonPayload = {
  __typename?: 'updatePersonPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
};

/** UpdatePoNumbers a BookingItem. */
export type UpdatePoNumberBookingItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  poNumber: Scalars['String'];
};

/** UpdatePoNumbers a BookingItem. */
export type UpdatePoNumberBookingItemPayload = {
  __typename?: 'updatePoNumberBookingItemPayload';
  bookingItem?: Maybe<BookingItem>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Updates a PostCourseFeedback. */
export type UpdatePostCourseFeedbackInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  postCourseQuestionaireAnswers?: InputMaybe<Scalars['Iterable']>;
  receiveNewsAndUpdates?: InputMaybe<Scalars['Boolean']>;
  useInMarketing?: InputMaybe<Scalars['Boolean']>;
};

/** Updates a PostCourseFeedback. */
export type UpdatePostCourseFeedbackPayload = {
  __typename?: 'updatePostCourseFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  postCourseFeedback?: Maybe<PostCourseFeedback>;
};

/** Updates a PreCourseEnrollment. */
export type UpdatePreCourseEnrollmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  preCourseQuestionaireAnswers?: InputMaybe<Scalars['Iterable']>;
  reviewedOutline?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a PreCourseEnrollment. */
export type UpdatePreCourseEnrollmentPayload = {
  __typename?: 'updatePreCourseEnrollmentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  preCourseEnrollment?: Maybe<PreCourseEnrollment>;
};

/** Updates a PurchaseOrderEvent. */
export type UpdatePurchaseOrderEventInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a PurchaseOrderEvent. */
export type UpdatePurchaseOrderEventPayload = {
  __typename?: 'updatePurchaseOrderEventPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderEvent?: Maybe<PurchaseOrderEvent>;
};

/** Updates a PurchaseOrderItem. */
export type UpdatePurchaseOrderItemInput = {
  bookingItem: UpdateBookingItemNestedInput;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  publicSchedule?: InputMaybe<Scalars['String']>;
  purchaseOrder?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['String']>;
  taxRatePercent?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  unitPrice?: InputMaybe<MoneyInput>;
};

/** Updates a PurchaseOrderItem. */
export type UpdatePurchaseOrderItemPayload = {
  __typename?: 'updatePurchaseOrderItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  purchaseOrderItem?: Maybe<PurchaseOrderItem>;
};

/** Updates a SupplierContact. */
export type UpdateSupplierContactInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Updates a SupplierContact. */
export type UpdateSupplierContactPayload = {
  __typename?: 'updateSupplierContactPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierContact?: Maybe<SupplierContact>;
};

/** Updates a Supplier. */
export type UpdateSupplierInput = {
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankSortCode?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  primaryContact?: InputMaybe<Scalars['String']>;
  purchasesEmail?: InputMaybe<Scalars['String']>;
  purchasesLocation?: InputMaybe<Scalars['String']>;
  registrationNumber?: InputMaybe<Scalars['String']>;
  remittanceEmail?: InputMaybe<Scalars['String']>;
  trainers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Updates a SupplierLocation. */
export type UpdateSupplierLocationInput = {
  address?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  supplier?: InputMaybe<Scalars['String']>;
};

/** Updates a SupplierLocation. */
export type UpdateSupplierLocationPayload = {
  __typename?: 'updateSupplierLocationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplierLocation?: Maybe<SupplierLocation>;
};

/** Updates a Supplier. */
export type UpdateSupplierPayload = {
  __typename?: 'updateSupplierPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  supplier?: Maybe<Supplier>;
};

/** Updates a Ticket. */
export type UpdateTicketInput = {
  booking?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: InputMaybe<TicketStatus>;
  subject?: InputMaybe<Scalars['String']>;
};

/** Updates a Ticket. */
export type UpdateTicketPayload = {
  __typename?: 'updateTicketPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  ticket?: Maybe<Ticket>;
};

/** Updates a TrainerCourse. */
export type UpdateTrainerCourseInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  competency?: InputMaybe<Scalars['String']>;
  competencySelf?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  rate?: InputMaybe<MoneyInput>;
  trainer?: InputMaybe<Scalars['String']>;
};

/** Updates a TrainerCourse. */
export type UpdateTrainerCoursePayload = {
  __typename?: 'updateTrainerCoursePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainerCourse?: Maybe<TrainerCourse>;
};

/** Updates a Trainer. */
export type UpdateTrainerInput = {
  calendarUrl?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedInUrl?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  preferred?: InputMaybe<Scalars['Boolean']>;
  supplier?: InputMaybe<Scalars['String']>;
  trainerCourses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trainingClasses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Updates a Trainer. */
export type UpdateTrainerPayload = {
  __typename?: 'updateTrainerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainer?: Maybe<Trainer>;
};

/** Updates a TrainerPostCourseFeedback. */
export type UpdateTrainerPostCourseFeedbackInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  postCourseFeedbackAnswers?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a TrainerPostCourseFeedback. */
export type UpdateTrainerPostCourseFeedbackPayload = {
  __typename?: 'updateTrainerPostCourseFeedbackPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainerPostCourseFeedback?: Maybe<TrainerPostCourseFeedback>;
};

/** Updates a TrainingClass. */
export type UpdateTrainingClassInput = {
  additionalNotes?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a TrainingClass. */
export type UpdateTrainingClassPayload = {
  __typename?: 'updateTrainingClassPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingClass?: Maybe<TrainingClass>;
};

/** Updates a TrainingSeat. */
export type UpdateTrainingSeatInput = {
  attendance?: InputMaybe<Attendance>;
  attendanceComments?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  seatNumber?: InputMaybe<Scalars['Int']>;
  trainingSeatReservation?: InputMaybe<Scalars['String']>;
  trainingSession?: InputMaybe<Scalars['String']>;
};

/** Updates a TrainingSeat. */
export type UpdateTrainingSeatPayload = {
  __typename?: 'updateTrainingSeatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeat?: Maybe<TrainingSeat>;
};

/** Updates a TrainingSeatReservation. */
export type UpdateTrainingSeatReservationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a TrainingSeatReservation. */
export type UpdateTrainingSeatReservationPayload = {
  __typename?: 'updateTrainingSeatReservationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  trainingSeatReservation?: Maybe<TrainingSeatReservation>;
};

/** Updates a User. */
export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['Iterable']>;
};

/** Updates a User. */
export type UpdateUserPayload = {
  __typename?: 'updateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** Updates a WorkflowAction. */
export type UpdateWorkflowActionInput = {
  actionType?: InputMaybe<WorkflowActionType>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailTemplate?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  targetContactTypes?: InputMaybe<Scalars['Iterable']>;
  workflow?: InputMaybe<Scalars['String']>;
};

/** Updates a WorkflowAction. */
export type UpdateWorkflowActionPayload = {
  __typename?: 'updateWorkflowActionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowAction?: Maybe<WorkflowAction>;
};

/** Updates a WorkflowErrorLog. */
export type UpdateWorkflowErrorLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Updates a WorkflowErrorLog. */
export type UpdateWorkflowErrorLogPayload = {
  __typename?: 'updateWorkflowErrorLogPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflowErrorLog?: Maybe<WorkflowErrorLog>;
};

/** Updates a Workflow. */
export type UpdateWorkflowInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  active?: InputMaybe<Scalars['Boolean']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  targetEvents?: InputMaybe<Scalars['Iterable']>;
  targetResource?: InputMaybe<TargetResource>;
};

/** Updates a Workflow. */
export type UpdateWorkflowPayload = {
  __typename?: 'updateWorkflowPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workflow?: Maybe<Workflow>;
};

/** Uploads a Asset. */
export type UploadAssetInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  course?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  fileType?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Uploads a Asset. */
export type UploadAssetPayload = {
  __typename?: 'uploadAssetPayload';
  asset?: Maybe<Asset>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** UploadAvatars a User. */
export type UploadAvatarUserInput = {
  avatar: Scalars['Upload'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** UploadAvatars a User. */
export type UploadAvatarUserPayload = {
  __typename?: 'uploadAvatarUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};
