export const items = [
    {
        icon: "mdi-school",
        roles: ["ROLE_SUPPLIER"],
        title: "nav.training_courses",
        to: { name: "training-class.index" },
    },
    {
        title: "nav.purchase_orders",
        roles: ["ROLE_SUPPLIER"],
        icon: "mdi-cart-variant",
        to: { name: "purchase-order.index" },
    },
    {
        title: "nav.calendar_feed",
        roles: ["ROLE_SUPPLIER"],
        icon: "mdi-calendar-month",
        to: { name: "calendar.index" },
    },
];
