import SupplierAuthService from "@/service/SupplierAuthService";
import store from "@/store";
import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { User } from "sx-shared-core";
import { onLogout, onLogin, apolloClient } from "@/vue-apollo";
import { Route } from "vue-router";

export const TOKEN_KEY = "ody-token";
export const ERROR_LOGIN_FAILED = "auth.error.login_failed";

export interface AuthStoreState {
    errors: string[];
    user: User | null;
    token: string | null;
    authenticated: boolean;
}

@Module({
    dynamic: true,
    store: store,
    name: "auth",
})
export default class AuthStore extends VuexModule implements AuthStoreState {
    token = localStorage.getItem(TOKEN_KEY) || "";
    authenticated = SupplierAuthService.validateToken(this.token);
    errors: string[] = [];
    previousRoute: Route | null = null;
    user: User | null = null;

    get currentUser(): User | null {
        return this.user;
    }

    get isAuthenticated(): boolean {
        return this.authenticated;
    }

    get hasRole() {
        return (role: string | Array<string>): boolean => {
            if (!this.user) {
                return false;
            }

            if (Array.isArray(role)) {
                let roleFound = false;
                role.forEach((item) => {
                    if (this.user && this.user.roles.includes(item)) {
                        roleFound = true;
                    }
                });
                return roleFound;
            }

            return this.user.roles.includes(role);
        };
    }

    get getPreviousRoute(): Route | null {
        return this.previousRoute;
    }

    @Action({ rawError: true })
    checkLogin(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.token) {
                const validToken = SupplierAuthService.validateToken(
                    this.token
                );
                if (validToken) {
                    this.checkUser().then((validUser) => {
                        this.setAuthenticated(validUser);
                        if (validUser) {
                            onLogin(apolloClient, this.token);
                            resolve(true);
                        } else {
                            this.purge();
                            resolve(false);
                        }
                    });
                } else {
                    this.purge();
                    resolve(false);
                }
            } else {
                this.purge();
                resolve(false);
            }
        });
    }

    @Action({ rawError: true })
    checkUser(): Promise<boolean> {
        return new Promise((resolve) => {
            this.loadUser()
                .then((user) => {
                    if (SupplierAuthService.validateUserRoles(user)) {
                        this.setUser(user);
                        resolve(true);
                    } else {
                        this.purge();
                        this.addError("auth.error.invalid_user_roles");
                        resolve(false);
                    }
                })
                .catch(() => {
                    this.purge();
                    resolve(false);
                });
        });
    }

    @Action({ rawError: true })
    loadUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            if (this.user) {
                resolve(this.user);
                return;
            }
            SupplierAuthService.loadUser(this.token)
                .then((user) => {
                    resolve(user);
                })
                .catch((reason) => {
                    this.addError("auth.error.get_user_failed");
                    reject(reason);
                });
        });
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    login(data: any): Promise<string> {
        return new Promise((resolve, reject) => {
            SupplierAuthService.login(data.email, data.password)
                .then((token) => {
                    this.setToken(token);
                    this.checkUser().then((value) => {
                        if (value) {
                            resolve(this.token);
                        } else {
                            reject(ERROR_LOGIN_FAILED);
                        }
                    });
                })
                .catch((error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.addError(ERROR_LOGIN_FAILED);
                    reject(message);
                });
        });
    }

    @Action
    logout(): void {
        this.purge();
    }

    @Mutation
    setAuthenticated(authenticated: boolean): void {
        this.authenticated = authenticated;
    }

    @Mutation
    addError(error: string): void {
        this.errors.push(error);
    }

    @Mutation
    setToken(token: string): void {
        this.errors = [];
        this.token = token;
        localStorage.setItem(TOKEN_KEY, token);
    }

    @Mutation
    setUser(user: User): void {
        this.user = user;
    }

    @Mutation
    purge(): void {
        this.user = null;
        this.errors = [];
        this.token = "";
        this.authenticated = false;
        localStorage.removeItem(TOKEN_KEY);
        onLogout(apolloClient);
    }

    @Mutation
    setPreviousRoute(route: Route | null): void {
        this.previousRoute = route;
    }
}
